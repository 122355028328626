<div class="custom-pagination">
  <div class="custom-pagination__numbers">
      <button type="button" [disabled]="this.pageInfo.pageIndex <= 0" class="ac p-ac custom-pagination__previous " (click)="onPrevious()" data-test-id="pagination-previous-btn"> <mat-icon>chevron_left</mat-icon> Previous</button>
      <ng-container *ngFor="let page of pages; let i = index">
        <button class="custom-pagination__btn-number" *ngIf="i < pageInfo.maxPagesToShow" [disabled]="page.disabled" (click)="goToPage(page.index)" data-test-id="pagination-page-btn">{{ page.label }}</button>
      </ng-container>
      <ng-container *ngIf="pages.length > pageInfo.maxPagesToShow">
        <button [disabled]="true" class="dots" data-test-id="pagination-dots" class="custom-pagination__btn-number custom-pagination__btn-number--dots">...</button>
        <button [disabled]="totalPages === pageInfo.pageIndex + 1" (click)="goToPage(totalPages - 1)" data-test-id="pagination-lastpage-btn" class="custom-pagination__btn-number">{{totalPages}}</button>
      </ng-container>
      <button type="button" [disabled]="this.totalPages === 0 || this.pageInfo.pageIndex + 1 === this.totalPages" class="ac n-ac custom-pagination__next" (click)="onNext()" data-test-id="pagination-next-btn">Next <mat-icon>chevron_right</mat-icon> </button>
  </div>

  <div class="custom-pagination__show-action">
    <p *ngIf="length === 0">Showing <b>0 {{listType}}</b></p>
    <p *ngIf="length > 0">Showing <b>{{pageInfo.pageSize}} {{listType}}</b></p>

    <mat-form-field>
      <mat-select (selectionChange)="onChangePageSize($event)" [(value)]="pageInfo.pageSize">
        <mat-option *ngFor="let pageSize of pageInfo.pageSizeOptions" [value]="pageSize">
          {{pageSize}}
        </mat-option>
      </mat-select>
      <mat-icon matSuffix>expand_more</mat-icon>
    </mat-form-field>
  </div>

</div>
