import { Directive, Input, OnInit, OnDestroy, ElementRef, Renderer2 } from '@angular/core';
import moment from 'moment';

@Directive({
  selector: '[realTimer]'
})
export class RealTimerDirective implements OnInit, OnDestroy {
  @Input('realTimer') startTime: string; // Datetime string for each row
  @Input() format: string = 'DHMS'; // Default format

  private intervalId: any;

  constructor(private el: ElementRef, private renderer: Renderer2) { }

  ngOnInit(): void {
    this.updateTimeDifference(); // Initial calculation

    // Update time difference every second
    this.intervalId = setInterval(() => {
      this.updateTimeDifference();
    }, 1000);
  }

  ngOnDestroy(): void {
    clearInterval(this.intervalId);
  }

  updateTimeDifference(): void {
    const startDate = moment.utc(this.startTime); // Convert UTC time to moment object
    const currentDate = moment(); // Current local time

   const diff = moment.duration(currentDate.diff(startDate)); // Calculate duration difference

    let displayString = '';
    if (this.format.includes('D') && diff.days() > 0) {
      displayString += `${diff.days()} D : `;
    }
    if (this.format.includes('H') && diff.hours() > 0) {
      if (this.format === 'HMS') {
        const daysAsHours = diff.days() * 24; // Convert days to hours
        const totalHours = daysAsHours + diff.hours(); // Total hours
        displayString += `${totalHours} H : `;
      } else {
        displayString += `${diff.hours()} H : `;
      }
    }
    if (this.format.includes('M')) {
      displayString += `${diff.minutes()} M : `;
    }
    if (this.format.includes('S')) {
      displayString += `${diff.seconds()} S`;
    }

    this.renderer.setProperty(this.el.nativeElement, 'innerText', displayString);
  }
}
