export function detectIE(): boolean {
  if (
    navigator.userAgent.indexOf('MSIE ') > -1 ||
    navigator.userAgent.indexOf('Trident/') > -1 ||
    navigator.userAgent.indexOf('Edge/') > -1
  ) {
    return true;
  }
  return false;
}

export function detectIOS(): boolean {
  return !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);
}

export function detectFirefox(): boolean {
  return navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
}

export function isMobileDevice(): boolean {
  return (
    typeof window.orientation !== 'undefined' || navigator.userAgent.indexOf('IEMobile') !== -1
  );
}

export function detectMAC() {
  return navigator.platform && navigator.platform.toLowerCase().indexOf('mac') > -1;
}

export function getUserAgent(): string {
  return navigator.userAgent;
}

export function detectDarkMode(): boolean {
  return window.matchMedia('(prefers-color-scheme: dark)').matches;
}

export function detectIphoneSafari() {
  const ua = window.navigator.userAgent;
  const iOS = !!ua.match(/iPad/i) || !!ua.match(/iPhone/i);
  const webkit = !!ua.match(/WebKit/i);
  const iOSSafari = iOS && webkit && !ua.match(/CriOS/i);
  return iOSSafari;
}

export function copyToClipboard(string: string): void {
  const textarea: HTMLTextAreaElement = document.createElement('textarea');
  try {
    textarea.setAttribute('readonly', 'true');
    textarea.setAttribute('contenteditable', 'true');
    textarea.style.position = 'fixed'; // prevent scroll from jumping to the bottom when focus is set.
    textarea.value = string;

    if (detectIE()) {
      document.body.appendChild(textarea);
    } else {
      document.body.prepend(textarea);
    }

    textarea.focus();
    textarea.select();

    const range: Range = document.createRange();
    range.selectNodeContents(textarea);

    textarea.setSelectionRange(0, textarea.value.length);
    document.execCommand('copy');
  } catch (error) {
    console.error(error);
  } finally {
    document.body.removeChild(textarea);
  }
}

export function scrollElementIntoView(
  element: Element,
  scrollOptions?: ScrollIntoViewOptions,
): void {
  if (!scrollOptions) {
    scrollOptions = {
      behavior: 'smooth',
      block: 'end',
      inline: 'nearest',
    };
  }
  if (detectIE() || detectIOS()) {
    /*ScrollIntoviewOptions not supported as parameter in IE and Safari, therefore alignToTop parameter passed as false
    so the bottom of element is aligned with bottom of visible area of scrollable ancestor*/
    element.scrollIntoView(false);
  } else {
    /*In case of other browsers, scrollIntoViewOptions provided for a smooth scroll and alignment of bottom of element
    with bottom of visible area of scrollable ancestor*/
    element.scrollIntoView(scrollOptions);
  }
}

export function downloadFile(url: string): void {
  const link: HTMLAnchorElement = document.createElement('a');
  link.href = url;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  link.remove();
}

export function createAnddownloadFile(filename: string, content: string): void {
  const element: HTMLAnchorElement = document.createElement('a');
  element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(content));
  element.setAttribute('download', filename);
  element.style.display = 'none';
  document.body.appendChild(element);
  element.click();
  document.body.removeChild(element);
}

export function scrollElementTo(
  element: Element,
  scrollPosition = 0,
  animation = true,
): void {
  if (element) {
    element.scroll({
      top: scrollPosition,
      left: 0,
      behavior: animation ? 'smooth' : 'auto',
    });
  }
}

export function scrollElementToBottom(element: Element, animation = true): void {
  if (element) {
    element.scroll({
      top: element.scrollHeight,
      left: 0,
      behavior: animation ? 'smooth' : 'auto',
    });
  }
}

export function getUserLocale(): string {
  // For Chrome
  if (navigator.languages && navigator.languages.length) {
    return navigator.languages[0];
  } else {
    // For All browsers
    return navigator.language || 'en';
  }
}
