<div mat-dialog-title class="add-client__card-header">
  <h3>{{isEditMode ? 'Update' : 'Add'}} {{ruleEngine.GAAR}}</h3>
  <mat-icon fontSet="material-icons-outlined" mat-dialog-close
      data-test-id="add-client-close">highlight_off</mat-icon>
</div>
<form [formGroup]="addGaarRule">
  <div mat-dialog-content>
      <div class="add-client__card">
          <div class="add-client__card-body">
              <mat-form-field appearance="outline">
                <mat-label>Enter Rule Code</mat-label>
                <input matInput type="text" placeholder="Word..." formControlName="rule_code"
                    (focusout)="removeSpaces(addGaarRule.get('rule_code'))"
                    data-test-id="add-rule-name">
              </mat-form-field>

              <mat-form-field appearance="outline">
                  <mat-label>Description</mat-label>
                  <textarea matInput #inputField formControlName="description"
                  data-test-id="add-description" (focusout)="removeSpaces(addGaarRule.get('description'))"></textarea>
              </mat-form-field>
          </div>
      </div>
  </div>
  <div mat-dialog-actions class="add-client__actions">
      <button type="button" class="add-client__btn-outlined" mat-dialog-close data-test-id="add-rule-close">Cancel</button>
      <button type="button" class="add-client__btn-filled" [disabled]="!addGaarRule.valid"
          (click)="onAddGaarRule(addGaarRule.value)" data-test-id="add-rule-save">{{isEditMode ? 'Update' : 'Add'}}</button>
  </div>
</form>
