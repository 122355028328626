<div mat-dialog-title class="add-client__card-header">
  <h3>{{isEditMode ? 'Update' : 'Add'}} {{ruleEngine.QUESTIONS}}</h3>
  <mat-icon fontSet="material-icons-outlined" mat-dialog-close
      data-test-id="add-client-close">highlight_off</mat-icon>
</div>
<form [formGroup]="addQuestion">
  <div mat-dialog-content>
      <div class="add-client__card">
          <div class="add-client__card-body">
              <mat-form-field appearance="outline">
                  <mat-label>Type your question</mat-label>
                  <textarea matInput #inputField formControlName="question"
                  data-test-id="add-question" (focusout)="removeSpaces(addQuestion.get('question'))"></textarea>
              </mat-form-field>
          </div>
      </div>
  </div>
  <div mat-dialog-actions class="add-client__actions">
      <button type="button" class="add-client__btn-outlined" mat-dialog-close data-test-id="add-rule-close">Cancel</button>
      <button type="button" class="add-client__btn-filled" [disabled]="!addQuestion.valid"
          (click)="onAddQuestion(addQuestion.value)" data-test-id="add-rule-save">{{isEditMode ? 'Update' : 'Add'}}</button>
  </div>
</form>
