<div mat-dialog-title class="create-review__card-header">
  <h3>Send Email</h3>
  <mat-icon fontSet="material-icons-outlined" mat-dialog-close
    data-test-id="create-review-close">highlight_off</mat-icon>
</div>
<form >
  <div mat-dialog-content>
    <div class="create-review__card">
      <div class="create-review__card-body">

        <mat-form-field class="example-chip-list">
          <mat-label>Email Address</mat-label>
          <mat-chip-grid #chipGrid aria-label="Enter emails">
            <mat-chip-row *ngFor="let email of emails" (removed)="remove(email)" >
              {{email}}
              <button matChipRemove [attr.aria-label]="'remove ' + email">
                <mat-icon>cancel</mat-icon>
              </button>
            </mat-chip-row>
            <input id="input-email" placeholder="New email..." [matChipInputFor]="chipGrid"
              [matChipInputSeparatorKeyCodes]="separatorKeysCodes" [matChipInputAddOnBlur]="addOnBlur"
              (matChipInputTokenEnd)="add($event)" />
          </mat-chip-grid>
        </mat-form-field>
      </div>
    </div>
  </div>
  <div mat-dialog-actions class="create-review__actions">
    <button class="create-review__btn-filled" [disabled]="emails.length === 0"
      (click)="onSendEmail()" data-test-id="create-review-save">Send</button>
  </div>
</form>