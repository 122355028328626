// eslint-disable-next-line
export const permission = {
  dashboard: ['dashboard.view'],
  analytics: ['analytics.view'],
  performance: ['performance.view'],
  orders: ['orders.view'],
  messages: ['messages.view'],
  rules: ['rules.view'],
  clientProfiles: ['client.view.admin'],
  users:['users.view'],
  settings: ['settings.view'],
  notifications: ['notifications.view'],
  computerVision: ['cv.view'],
  ocr: ['ocr.view'],
  roleManagement: ['rm.view'],
  reviewTier: ['rt.view', 'sa.view'],
  releaseNotes: ['rn.view'],
};

export const ActionPermissions = {
  ORDER_REVIEW: 'orders.view',
  REQUEST_FOR_REVISION: 'order.review.request_for_revision',
  MARK_AS_COMPLETE: 'order.review.mark_as_complete',
  REVIEWER_ASSIGNMENT: 'order.reviewer_assignment',
  ESCALATE_TO: 'order.escalate_to',
  ASSIGN_MYSELF: 'order.review.assign_myself',
  ARCHIVE_ORDER: 'order.review.archive_order',
  STIPULATION: 'order.review.stipulation',
  DELETE_MESSAGE: 'messages.delete',
  ORDER_REVIEW_CREATE: "order.review.create",
  VIEW_CLIENTS: "client.view.admin",
  USERS_UPDATE: "users.update",
  GET_NEXT_REVIEW: 'order.get_next_review',
  NAN_RULE_ASSOCIATE: 'nan.rules.associate',
  NAN_RULE_DISASSOCIATE: 'nan.rules.dissociate',
  ACI_RULE_MAPPING: 'aci.rules.mapping',
  GAAR_RULE_MAPPING: 'gaar.rules.mapping',
  EAD_RULE_MAPPING: 'ead.rules.mapping',
  UCDP_RULE_MAPPING: 'ucdp.rules.mapping',
  ADMIN_DASHBOARD: 'dashboard.admin.view',
  MANAGE_ORDERS_OOO: 'order.admin_ooo',
  MESSAGES_VIEW: 'messages.view',
  STANDALONE_VIEW: 'sa.view',
  STANDALONE_CREATE: 'sa.create',
  STANDALONE_UPDATE: 'sa.update',
  STANDALONE_USER_UPDATE: 'sa.user.update',
  STANDALONE_DELETE: 'sa.delete',
  REVIEWTIER_VIEW: 'rt.view',
  REVIEWTIER_CREATE: 'rt.create',
  REVIEWTIER_UPDATE: 'rt.update',
  REVIEWTIER_USER_UPDATE: 'rt.user.update',
  REVIEWTIER_DELETE: 'rt.delete',
  REVIEWTIER_PREVIEW: 'rt.preview',
  STANDALONE_PREVIEW: 'sa.preview',
  RELEASE_NOTES: 'rn.view',
}
