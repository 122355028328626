import { Component } from '@angular/core';
import { message } from '../../constants/alerts_messages';
import { ToastService } from 'src/app/core/services/toast.service';
import { SharedService } from '../../shared.service';

@Component({
  selector: 'app-history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.scss']
})
export class HistoryComponent {
  noResultsFound = message.NoResultsFound;
  sortedGroups = [];

  constructor(
    private toastr: ToastService,
    public sharedService: SharedService
  ) { }

}
