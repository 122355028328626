<div class="finding-stats">
  <div class="finding-stats__head">
    <div class="finding-stats__foot">
      <div class="finding-stats__progress-wrapper">
        <div class="finding-stats__progress-count">{{ calculateProgress() }}% </div>
        <h6>Progress Indicator</h6>
      </div>
      <app-findings-progress-bar [progress]="calculateProgress()"></app-findings-progress-bar>
    </div>

    <div class="finding-stats__stip-wrapper" *ngIf="!token && userConfig.checkPermissions(permission.STIPULATION)">
      <button class="finding-stats__stip" (click)="downloadPDF()" mat-menu-item>
        <mat-icon fontSet="material-icons-outlined">file_download</mat-icon>
      </button>
      <button class="finding-stats__stip" (click)="openSendEmailModal()" mat-menu-item>
        <mat-icon fontSet="material-icons-outlined">email</mat-icon>
      </button>
    </div>
  </div>
  <div class="finding-stats__count-list">
    <div class="finding-stats__count" [class.finding-stats__count--active]="activeFilter === 'All'"
    (click)="filterFindings('All')">
      {{ totalfindings ? totalfindings : 0 }}
      <span>Findings</span>
    </div>
    <div class="finding-stats__count" [class.finding-stats__count--active]="activeFilter === 'resolved'"
    (click)="filterFindings('resolved')">
      {{ resolvedfindings ? resolvedfindings : 0 }}
      <span>Completed</span>
    </div>
    <div class="finding-stats__count" [class.finding-stats__count--active]="activeFilter === 'unResolved'"
    (click)="filterFindings('unResolved')">
      {{ totalfindings ? totalfindings - resolvedfindings : 0}}
      <span>Unresolved</span>
    </div>
  </div>
  <div class="order-submission" [ngClass]="{'slide-active': showDetails}">
    <div class="order-submission__wrapper">
      <div class="order-submission__stat">
        <div class="headline">Total Findings</div>
        <div class="total-count">{{ totalfindings }}</div>
      </div>
      <div class="order-submission__stat">
        <div class="headline">Accepted Findings</div>
        <div class="total-count" [ngClass]="{'approved': countData['Approved'] !== 0}">{{ countData['Approved'] === 0 ? '--' : countData['Approved'] }}</div>
      </div>
      <div class="order-submission__stat">
        <div class="headline">Revision Findings</div>
        <div class="total-count" [ngClass]="{'reject': countData['Rejected'] !== 0}">{{ countData['Rejected'] === 0 ? '--' : countData['Rejected']}}</div>
      </div>
      <div class="order-submission__stat">
        <div class="headline">Not Applicable Findings</div>
        <div class="total-count" [ngClass]="{'NA': countData['Not Applicable'] !== 0}">{{ countData['Not Applicable'] === 0 ? '--' : countData['Not Applicable']}}</div>
      </div>
    </div>
    <div class="order-submission__btn-wrapper">
      <button class="btn-error" type="submit" data-test-id="request-for-revision"
      [disabled]="rejectedFindings === 0 || isPreview || vendorAndReviewer && !vendorAndReviewer?.assigned_to_reviewer"
            *ngIf="!token && vendorAndReviewer && !vendorAndReviewer?.assigned_to_vendor"
      (click)="requestForRevision()">Request For Revision</button>
      <button class="btn-error" type="submit" data-test-id="request-for-revision"
      [disabled]="rejectedFindings === 0 || isPreview || vendorAndReviewer && !vendorAndReviewer?.assigned_to_reviewer"
      *ngIf="!token && vendorAndReviewer && vendorAndReviewer?.assigned_to_vendor"
      (click)="backToReport.emit()">Request For Revision</button>
      <button class="btn-success" type="submit" data-attr="btnLogin" data-test-id="mark-as-completed"
      [disabled]="rejectedFindings > 0 || resolvedfindings !== totalfindings || isPreview"
      *ngIf="!token"
      (click)="markAsComplete()">Mark As Completed</button>
    </div>
  </div>
  <div class="order-submission__toggle-btn" [ngClass]="{'isActive': showDetails}">
    <button mat-button [disableRipple]="true" (click)="toggleSummary()">{{!showDetails ? 'Show Details' : 'Hide Details'}}<mat-icon iconPositionEnd>expand_more</mat-icon></button>
  </div>
</div>
