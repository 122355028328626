<div mat-dialog-title class="completion-modal__card-header">
  <h3>{{heading}}</h3>
  <mat-icon fontSet="material-icons-outlined" (click)="close()">highlight_off</mat-icon>
</div>
<div mat-dialog-content>
  <div class="completion-modal__card">
    <div class="completion-modal__card-body">
      <p [innerHTML]="message"></p>
    </div>
  </div>
</div>
<div mat-dialog-actions class="completion-modal__actions">
  <button class="completion-modal__btn-outlined"
  (click)="close()">Close</button>
  <button class="completion-modal__btn-filled"
  *ngIf="actionHeading"
  (click)="submit()">{{actionHeading}}</button>
</div>
