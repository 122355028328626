import { Component, Inject } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ToastService } from 'src/app/core/services/toast.service';
import { message } from 'src/app/shared/constants/alerts_messages';
import { RuleEngineList, RuleEngineListSingular } from 'src/app/shared/enums';
import { RulesService } from '../rules.service';

@Component({
  selector: 'app-create-gaar-rules',
  templateUrl: './create-gaar-rules.component.html',
  styleUrls: ['./create-gaar-rules.component.scss']
})
export class CreateGaarRulesComponent {
  ruleEngine = RuleEngineList;
  addGaarRule: FormGroup;
  submitted = false;
  clientId: any;
  isEditMode = false;

  constructor(private toastr: ToastService,
    private rulesService: RulesService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<any>) {
    this.addGaarRule = new FormGroup({
      rule_code: new FormControl('', [Validators.required]),
      description: new FormControl('', [Validators.required])
    });
    this.clientId = data.clientId;
    if (data?.data?.id) {
      this.addGaarRule.get('rule_code')?.setValue(data.data.rule_code);
      this.addGaarRule.get('description')?.setValue(data.data.description);
      this.isEditMode = true;
    }
  }

  onAddGaarRule(formValue: any): void {
    this.submitted = true;
    if (!this.addGaarRule.valid) {
      return;
    }
    const param: FormData = new FormData();
    if (this.data.data) {
      formValue.rule_id = this.data.data.id
      param.append('gaar', JSON.stringify(formValue));
      this.updateGaarRule(param, formValue.rule_id);
    } else {
      param.append('gaar', JSON.stringify(formValue));
      this.postGaarRule(param);
      this.addGaarRule.reset();
    }
  }


  postGaarRule(param: any) {
    this.rulesService.createGaarRule(param, this.clientId).then((response) => {
      this.toastr.success(`${RuleEngineListSingular.GAAR} ${message.ruleCreated}`);
      this.dialogRef.close(true);
    }, error => {
      this.toastr.error(error.error.detail);
    })
  }

  updateGaarRule(param: any, rule_id: string) {
    this.rulesService.updateGaarRule(param, this.clientId, rule_id).then((response) => {
      this.toastr.success(`${RuleEngineListSingular.GAAR} ${message.ruleUpdated}`);
      this.dialogRef.close(true);
    }, error => {
      this.toastr.error(error.error.detail);
    })
  }


  removeSpaces(control: any): void {
    const newValue = control.value.trim();
    control.setValue(newValue);
  }
}
