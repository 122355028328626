export enum RuleEngineList {
  ACI_RULES = 'ACI Rules',
  NAN_STANDARD_RULES = 'NAN Standard Rules',
  RESTRICTED_WORDS = 'Restricted Words',
  QUESTIONS = 'Questions',
  MANUAL_FINDING_GEOUPS = 'Manual Finding Groups',
  GAAR = 'GAAR Rules',
  RULE_MAPPING = 'Rule Mapping'
}

export enum RuleEngineListSingular {
  ACI_RULE = 'ACI Rule',
  NAN_STANDARD_RULE = 'NAN Standard Rule',
  RESTRICTED_WORD = 'Restricted Word',
  QUESTION = 'Question',
  MANUAL_FINDING_GEOUPS = 'Manual Finding Group',
  GAAR = 'GAAR Rule',
  RULE_MAPPING = 'Rule Mapping'
}

export enum NSRulesMappingList {
  ACI_RULES = 'ACI Rules',
  GAAR_RULES = 'GAAR Rules',
  EAD_RULES = 'EAD Rules',
  UCDP_RULES = 'UCDP Rules',
}

export enum RuleEngineMapping {
  ACI_RULE = 'aci',
  GAAR = 'gaar',
  EAD_RULES = 'ead',
  UCDP_RULES = 'ucdp',
}
