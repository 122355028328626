<div mat-dialog-title class="priority-modal__card-header">
  <h3>{{displayData.heading}}</h3>
  <mat-icon fontSet="material-icons-outlined" mat-dialog-close>highlight_off</mat-icon>
</div>
<div mat-dialog-content>
  <div class="priority-modal__card">
    <div class="priority-modal__card-body">
      <p>{{displayData.message}}</p>
      <div cdkDropList (cdkDropListDropped)="drop($event)" *ngIf="!displayData.isPreview">
        <div class="priority-list" *ngFor="let item of displayData.items">
          <p cdkDrag><app-svg [svgPath]="displayData.img"></app-svg> {{item?.name === 'order_due_date' ? 'Order Due Date' : 'Review Creation Date'}}</p>
        </div>
      </div>
      <div *ngIf="displayData.isPreview">
        <div class="priority-list" *ngFor="let item of displayData.items">
          <p><app-svg [svgPath]="displayData.img"></app-svg> {{item?.name === 'order_due_date' ? 'Order Due Date' : 'Review Creation Date'}}</p>
        </div>
      </div>
    </div>
  </div>
</div>
<div mat-dialog-actions class="priority-modal__actions">
  <button class="priority-modal__btn-outlined" mat-dialog-close>Close</button>
  <button class="priority-modal__btn-filled" (click)="performAction()" *ngIf="!displayData?.isPreview">{{displayData.action ? displayData.action : 'Done'}}</button>
</div>
