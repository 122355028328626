import { VonniQCEnvironment } from './interface';
import { Environments } from './enums';

// Firebase Secrets
import secrets  from '../../secrets.json';

export const environment: VonniQCEnvironment = {
  name: Environments.DEVELOP,
  production: false,
  baseURL: 'https://api-dev.nan-ai.com/',
  showErrors: true,
  firebaseConfig: {
    apiKey: secrets.apiKey,
    authDomain: secrets.authDomain,
    projectId: secrets.projectId,
    storageBucket: secrets.storageBucket,
    messagingSenderId: secrets.messagingSenderId,
    appId: secrets.appId,
  },
  pdfViewer: secrets.pdfViewer,
  socketNamespace: secrets.socketNamespace
};
