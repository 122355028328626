import moment from 'moment';
import { getUserLocale } from './browser';

// To select date format based on user locale
export function getLocaleDateFormat(): string {
  const userLocaleId: string = getUserLocale();
  return moment
    .localeData(userLocaleId)
    .longDateFormat('L')
    .replace(/[/.]/g, '-');
}

export function convertUTCDateToLocalDate(date: any) {
  date = new Date(date);
  const newDate = new Date(date.getTime()+date.getTimezoneOffset()*60*1000);

  const offset = date.getTimezoneOffset() / 60;
  const hours = date.getHours();

  newDate.setHours(hours - offset);

  return newDate;
}

export function getLastWeekDate(format: string) {
  const lastWeekStartDate = moment().subtract(1, 'weeks').startOf('isoWeek');
  const lastWeekEndDate = moment().subtract(1, 'weeks').endOf('isoWeek');

  if (format) {
    return `${lastWeekStartDate.format(format)} - ${lastWeekEndDate.format(format)}`;
  } else {
    return { start: lastWeekStartDate, end: lastWeekEndDate };
  }
}

export function getCurrentWeekDate(format: string) {
  const currentWeekStartDate = moment().startOf('isoWeek');
  const currentWeekEndDate = moment().endOf('isoWeek');

  if (format) {
    return `${currentWeekStartDate.format(format)} - ${currentWeekEndDate.format(format)}`;
  } else {
    return { start: currentWeekStartDate, end: currentWeekEndDate };
  }
}

export function getLastMonthDate(format: string) {
  const lastMonthStartDate = moment().subtract(1, 'months').startOf('month');
  const lastMonthEndDate = moment().subtract(1, 'months').endOf('month');

  if (format) {
    return `${lastMonthStartDate.format(format)} - ${lastMonthEndDate.format(format)}`;
  } else {
    return { start: lastMonthStartDate, end: lastMonthEndDate };
  }
}

export function getCurrentMonthDate(format: string) {
  const currentMonthStartDate = moment().startOf('month');
  const currentMonthEndDate = moment().endOf('month');

  if (format) {
    return `${currentMonthStartDate.format(format)} - ${currentMonthEndDate.format(format)}`;
  } else {
    return { start: currentMonthStartDate, end: currentMonthEndDate };
  }
}


export function getWeeksAndDates(year: number, month:number) {
  // Create a Moment.js object for the first day of the specified month
  const startDate = moment([year, month, 1]);

  // Get the number of days in the month
  const daysInMonth = startDate.daysInMonth();

  // Initialize an array to store the weeks and their corresponding dates
  const weeksAndDates = [];

  let currentWeek = [];
  const currentDate = startDate.clone();

  for (let day = 1; day <= daysInMonth; day++) {
    currentWeek.push(currentDate.format('MMM DD'));
    currentDate.add(1, 'day');

    // If it's a Sunday or the last day of the month, start a new week
    if (currentDate.day() === 1 || day === daysInMonth) {
      weeksAndDates.push(currentWeek);
      currentWeek = [];
    }
  }

  return weeksAndDates;

}

export function getMonthIndex(dateString: string) {
  const date = moment(dateString);
  return date.month(); // Returns the month index from 0 to 11
}

export function getCurrentYear() {
  return moment().year();
}

export function getPreviousYear() {
  return moment().subtract(1, 'year').year();
}

export function getYearOfPreviousMonth() {
  const currentDate = moment();
  const previousMonthDate = currentDate.clone().subtract(1, 'month');

  if (previousMonthDate.year() <= currentDate.year()) {
    return previousMonthDate.year();
  } else {
    // Previous month is in the same year as the current date
    // Handle the case where the previous month is in the previous year
    return currentDate.year() - 1;
  }
}

export function convertToUTC(time: any) {
  const utcTime = moment.utc(moment(time, 'h:mm A')).format('HH:mm:ss.SSS[Z]');
  return utcTime;
};

export function convertToLocal(time: any) {
  const localTime = moment.utc(time, "HH:mm:ss").local().format("hh:mm a");
  return localTime;
};

export function compareTime(startTime: string, endTime:string) {
  const start = moment(startTime, 'h:mm a');
  const end = moment(endTime, 'h:mm a');
  return start.isAfter(end);
}
