import { Component, Input, ViewChild } from '@angular/core';
import { ChatComponent } from 'src/app/shared/components/chat/chat.component';
import { OrderResponse } from '../../orders.interface';
import { OrderStatuses, RevisionHistoryActions } from 'src/app/shared/enums/orders';

@Component({
  selector: 'app-order-messages',
  templateUrl: './order-messages.component.html',
  styleUrls: ['./order-messages.component.scss'],
})
export class OrderMessagesComponent {
  searchTerm: string;
  orderStatus: string;
  orderNo: string;
  isPreview = false;
  clientName: string | null;
  clientImage: any;
  reviewer: any;
  revisionAction = RevisionHistoryActions;
  lastOpenedIndex: any = null;

  @Input() orderId: string;
  @Input() orderDetail: OrderResponse;
  @Input() orderHistory: any;
  @Input() unAssigned: boolean;

  @ViewChild(ChatComponent) childComponent!: ChatComponent;

  ngOnChanges(): void {
    if (this.orderId) {
      this.getOrderDetails();
      this.getOrderHistory();
    }
  }

  getOrderDetails(): void {
    if (this.orderId && this.orderDetail) {
      this.orderNo = this.orderDetail?.order_number;
      this.orderStatus = this.orderDetail?.order_status?.status;

      if (
        this.orderStatus === OrderStatuses.COMPLETED ||
        this.orderStatus === OrderStatuses.REVISION_REQUESTED ||
        this.orderStatus === OrderStatuses.ARCHIVED
      ) {
        this.isPreview = true;
      }

      const {
        client: { company_name: clientName, avatar_link: clientAvatar },
        reviews,
      } = this.orderDetail;

      if (reviews?.length > 0) {
        this.reviewer = reviews[0];
      }

      this.clientName = `${clientName}`;
      this.clientImage = clientAvatar;
    }
  }

  searchMessages(): void {
    this.childComponent.searchMessagesByTerm(this.searchTerm);
  }

  getOrderHistory(): void {
    if (this.orderHistory?.data?.length > 0) {
      // Step 1: Create a new object to group objects by review_version_number
      const groupedObj:any = {};
      this.orderHistory.data.forEach((obj: any, i: number) => {
        const version = obj.review_version_number;
        if (version) {
          if (!groupedObj[version]) {
            groupedObj[version] = [];
          }
          groupedObj[version].push(obj);
          groupedObj[version]['panelOpenState'] = false;
        }
      });

      this.orderHistory = groupedObj;
      this.orderHistory[this.getLastKay()]['panelOpenState'] = true;
    }
  }

  getOrdinalRevision(num: any): string {
    const remainder10 = num % 10;
    const remainder100 = num % 100;
    const keys = Object.keys(this.orderHistory);
    const maxKey = Math.max(...keys.map(Number));

    if (maxKey == num) {
      return "Current Version";
    } else if (remainder10 === 2 && remainder100 !== 12) {
      return num + "nd Version";
    } else if (remainder10 === 3 && remainder100 !== 13) {
      return num + "rd Version";
    } else if (num == 1) {
      return num + "st Version";
    } else {
      return num + "th Version";
    }
  }

  getKeys(): string[] {
    return Object.keys(this.orderHistory);
  }

  getLastKay() {
    const keys = Object.keys(this.orderHistory);
    return Math.max(...keys.map(Number));
  }

  onPanelOpened(key: any) {
    if (this.lastOpenedIndex && this.lastOpenedIndex !== key) {
      this.orderHistory[this.lastOpenedIndex].panelOpenState = false;
    }
    this.orderHistory[key].panelOpenState = true;
    this.lastOpenedIndex = key;
  }

  onPanelClosed(key: any) {
    this.orderHistory[key].panelOpenState = false;
  }

}
