import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SharedService } from '../../shared.service';
import { ToastService } from 'src/app/core/services/toast.service';
import { SelectionModel } from '@angular/cdk/collections';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { ClientProfileService } from 'src/app/client-profile/client-profile.service';
import { message } from '../../constants/alerts_messages';
import { EadPayload, UcdpPayload } from '../../interfaces/clients';

export interface PeriodicElement {
  id: string;
  name: string;
  selected?: boolean;
}

@Component({
  selector: 'app-form-selection-modal',
  templateUrl: './form-selection-modal.component.html',
  styleUrls: ['./form-selection-modal.component.scss']
})
export class FormSelectionModalComponent {
  heading: string;
  displayedColumns: string[] = ['select', 'name'];
  dataSource: any = [];
  selection = new SelectionModel<PeriodicElement>(true, []);
  checkedIds: string[] = [];
  selectedCheckedIds: string[] = [];
  selectAllForms = false;
  previousSelection: string[] = [];

  submissionToggleState = false;
  resubmissionToggleState = false;
  isToggleEnabled = false;

  constructor(
    private toastr: ToastService,
    private sharedService: SharedService,
    private clientProfileService : ClientProfileService,
    public dialogRef: MatDialogRef<FormSelectionModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.heading = data.heading;
    this.submissionToggleState = this.data.clientDetails.is_auto_submission;
    this.resubmissionToggleState = this.data.clientDetails.is_auto_resubmission;
    this.previousSelection = this.data.clientDetails.forms?.map((form: any) => form.id) || [];
    this.selectedCheckedIds = [...new Set([...this.selectedCheckedIds, ...this.previousSelection])];
    if (this.selectedCheckedIds ) {
      this.isToggleEnabled = true;
    }
  }

  ngOnInit() {
    this.getAllForms();
  }

  getAllForms(): void {
    this.sharedService.getFormsName()
      .then((response) => {
        this.dataSource = response.data;
        this.dataSource.forEach((element: any) => {
          element.selected = this.previousSelection.includes(element.id);
        });
        const selectedForms = this.dataSource.filter((element: any) => element.selected);
        this.selection = new SelectionModel<PeriodicElement>(true, selectedForms);
      });
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  toggleAllRows() {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }

    this.selection.select(...this.dataSource);
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: PeriodicElement): string {
    if (!row) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.name + 1}`;
  }

  onAllCheckboxChange(event: MatCheckboxChange) {
    if (event?.checked) {
      this.selectAllForms = true;
      this.isToggleEnabled = true;
      this.dataSource.forEach((element: any) => {
        this.selectedCheckedIds.push(element.id)
      });
    } else {
      this.selectAllForms = false;
      this.isToggleEnabled = false;
      this.submissionToggleState = false;
      this.resubmissionToggleState = false;
      this.selectedCheckedIds = [];
    }
  }

  onCheckboxChange(event: MatCheckboxChange, row: any) {
    if (event?.checked) {
      this.selectedCheckedIds.push(row.id);
    } else {
      for (let i = 0; i < this.selectedCheckedIds.length; i++) {
        if (this.selectedCheckedIds[i] === row.id) {
          this.selectedCheckedIds.splice(i, 1);
          break;
        }
      }
    }
    this.dataSource.forEach((element: any) => {
      element.selected = this.selectedCheckedIds.includes(element.id);
    });

    if (this.selectedCheckedIds.length) {
      this.isToggleEnabled = true;
    } else {
      this.isToggleEnabled = false;
      this.submissionToggleState = false;
      this.resubmissionToggleState = false;
    }

    this.selectAllForms = false;
  }

  changeAutoSubmissionStatus(state: any) {
    this.submissionToggleState = state.target.checked;
  }

  changeAutoResubmissionStatus(state: any) {
    this.resubmissionToggleState = state.target.checked;
  }

  autoSubmissionConfigurations(): void {
    this.checkedIds = this.selectedCheckedIds;
    const payload = {
      is_auto_submission: this.submissionToggleState,
      is_auto_resubmission: this.resubmissionToggleState,
      form_types: this.checkedIds.map((id: string) => ({ id }))
    };
    this.data.type === 'EAD' ? this.updateEADFormSelection(payload) : this.updateUCDPFormSelection(payload)
  }

  updateEADFormSelection(payload: EadPayload): void {
    this.clientProfileService.updateEADconfigurations(this.data.clientID, payload)
    .then(
      () => {
        this.checkedIds = [];
        this.selectAllForms = false;
        this.dialogRef.close(true);
        this.toastr.success(message.updateConfigurations);
      },
      (err) => {
        this.toastr.error(err.message);
      },
    );
  }

  updateUCDPFormSelection(payload: UcdpPayload): void {
    this.clientProfileService.updateUCDPconfigurations(this.data.clientID, payload)
    .then(
      () => {
        this.checkedIds = [];
        this.selectAllForms = false;
        this.dialogRef.close(true);
        this.toastr.success(message.updateConfigurations);
      },
      (err) => {
        this.toastr.error(err.message);
      },
    );
  }
}
