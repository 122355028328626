import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { tap } from 'rxjs/operators';

import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpParams,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';

// Services
import { ApiCacheService } from '../services/api-cache.service';

@Injectable()
export class CachingInterceptor implements HttpInterceptor {
  constructor(private apiCacheService: ApiCacheService) {}

  intercept<T>(req: HttpRequest<T>, next: HttpHandler): Observable<HttpEvent<T>> {
    const isCachable: boolean = req.params.has('cache');
    const params: HttpParams = req.params.delete('cache');

    req = req.clone({
      params,
    });

    const cachedResponse = this.apiCacheService.get(req);
    return cachedResponse ? of(cachedResponse) : this.sendRequest<T>(isCachable, req, next);
  }

  sendRequest<T>(
    isCachable: boolean,
    req: HttpRequest<T>,
    next: HttpHandler,
  ): Observable<HttpEvent<T>> {
    return next.handle(req).pipe(
      tap(
        (event: HttpEvent<T>) => {
          if (isCachable && event instanceof HttpResponse) {
            this.apiCacheService.put(req, event);
          }
        },
        (err: Error) => {
          return throwError(err);
        },
      ),
    );
  }
}
