import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
import moment from 'moment';

@Pipe({
  name: 'localDate'
})
export class LocalDatePipe implements PipeTransform {
  constructor(private datePipe: DatePipe) {}

  transform(utcDate: string | null, format = 'medium'): string {
    if (utcDate === null) {
      return '';
    }
    const localDate: any = moment.utc(utcDate);

    return this.datePipe.transform(localDate, format) || '';
  }
}
