import { Component, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastService } from 'src/app/core/services/toast.service';
import { RuleEngineList, RuleEngineListSingular } from 'src/app/shared/enums';
import { RulesService } from '../rules.service';
import { SharedService } from 'src/app/shared/shared.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { message } from 'src/app/shared/constants/alerts_messages';

@Component({
  selector: 'app-create-manual-finding-group',
  templateUrl: './create-manual-finding-group.component.html',
  styleUrls: ['./create-manual-finding-group.component.scss']
})
export class CreateManualFindingGroupComponent {

  ruleEngine = RuleEngineList;
  addManualfindingGroup: FormGroup;
  submitted = false;
  isEditMode = false;
  constructor(
    private toastr: ToastService,
    private rulesService: RulesService,
    public sharedService: SharedService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<any>,
  ) {
    this.addManualfindingGroup = new FormGroup({
      mfg_code: new FormControl('', [Validators.required]),
      description: new FormControl('', [Validators.required]),
    });
    if (data?.data?.id) {
      this.addManualfindingGroup.patchValue(data.data);
      this.isEditMode = true;
    }
  }

  onAddManualfindingGroup(formValue: any): void {
    this.submitted = true;
    if (!this.addManualfindingGroup.valid) {
      return;
    }

    delete formValue.expression_search;

    const param: FormData = new FormData();
    param.append('mfg', JSON.stringify(formValue));

    if (this.isEditMode) {
      this.updateManualFindingGroup(param);
    } else {
      this.createManualFindingGroup(param);
    }
  }

  createManualFindingGroup(param: any) {
    this.rulesService.createManualFindingGroup(param)?.then(
      () => {
        this.toastr.success(`${RuleEngineListSingular.MANUAL_FINDING_GEOUPS} ${message.ruleCreated}`);
        this.dialogRef.close(true);
      },
      (error) => {
        this.toastr.error(error.error.detail);
      },
    );
  }

  updateManualFindingGroup (param: any) {
    const slug = 'mfg/'
    this.sharedService.ruleEngineUpdateStatues(slug, param, this.data.data.id)?.subscribe(
      () => {
        this.toastr.success(`${RuleEngineListSingular.MANUAL_FINDING_GEOUPS} ${message.ruleUpdated}`);
        this.dialogRef.close(true);
      },
      (error) => {
        this.toastr.error(error.error.detail);
      },
    );
  }
}
