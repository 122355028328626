import { Component, Input, OnInit } from '@angular/core';
import { SafeHtml, DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-svg',
  template: `<div [innerHTML]="svgContent" *ngIf="svgContent"></div>`,
})
export class SvgComponent implements OnInit {
  svgContent: SafeHtml | undefined;
  @Input() svgPath: any;
  @Input() renderSvg: boolean = false;
  @Input() approve: boolean = false;
  @Input() reject: boolean = false;
  @Input() notApplicable: boolean = false;

  constructor(private sanitizer: DomSanitizer) { }

  ngOnInit(): void {
    if (!this.renderSvg) {
      this.loadSvgFile(this.svgPath);
    }

    if (this.approve) {
      // NOSONAR: bypassing Angular's html sanitization
      this.svgContent = this.sanitizer.bypassSecurityTrustHtml(`<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_129_23940)">
      <path d="M16.4014 4.95605C16.1151 5.14831 16.0388 5.53711 16.2311 5.82336C17.0612 7.05933 17.5 8.50342 17.5 10C17.5 14.1357 14.1357 17.5 9.99999 17.5C5.86425 17.5 2.49999 14.1357 2.49999 10C2.49999 5.86426 5.86425 2.5 9.99999 2.5C11.499 2.5 12.9498 2.94311 14.1943 3.78052C14.4812 3.97461 14.8694 3.89832 15.0616 3.61145C15.2545 3.32519 15.1788 2.9364 14.8926 2.74414C13.4406 1.76636 11.7487 1.25 10 1.25C5.17517 1.25 1.25 5.17517 1.25 10C1.25 14.8248 5.17517 18.75 10 18.75C14.8248 18.75 18.75 14.8248 18.75 10C18.75 8.25439 18.2379 6.56921 17.2687 5.12634C17.0758 4.84009 16.6876 4.76257 16.4014 4.95605Z" fill="currentColor"/>
      <path d="M12.3108 7.79418L9.22546 10.8801L7.6892 9.34326C7.44507 9.09912 7.04956 9.09912 6.80542 9.34326C6.56128 9.58739 6.56128 9.98291 6.80542 10.227L8.78357 12.2058C8.90075 12.323 9.05945 12.3889 9.22546 12.3889C9.39147 12.3889 9.55017 12.323 9.66735 12.2058L13.1946 8.67797C13.4387 8.43384 13.4387 8.03832 13.1946 7.79419C12.9504 7.55005 12.5549 7.55004 12.3108 7.79418Z" fill="currentColor"/>
      </g>
      <defs>
      <clipPath id="clip0_129_23940">
      <rect width="20" height="20" fill="white"/>
      </clipPath>
      </defs>
      </svg>
      `);

    }
    if (this.reject) {
      // NOSONAR: bypassing Angular's html sanitization
      this.svgContent = this.sanitizer.bypassSecurityTrustHtml(`<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_129_23945)">
      <path d="M11.9362 9.96534L11.9028 10L11.9362 10.0347L14.2001 12.3868C14.45 12.6465 14.45 13.0678 14.2001 13.3275C14.0754 13.457 13.9126 13.5214 13.75 13.5214C13.5874 13.5214 13.4246 13.457 13.3 13.3275L11.036 10.9754L11 10.938L10.964 10.9754L8.70005 13.3275C8.70004 13.3276 8.70004 13.3276 8.70004 13.3276C8.57536 13.457 8.41257 13.5214 8.25 13.5214C8.08742 13.5214 7.92464 13.457 7.79996 13.3275C7.55006 13.0678 7.55005 12.6465 7.79997 12.3868L10.0638 10.0347L10.0972 10L10.0638 9.96534L7.79989 7.6132C7.54999 7.35356 7.54999 6.93219 7.7999 6.67247C8.04876 6.41385 8.45109 6.4139 8.70004 6.67248C8.70004 6.67248 8.70004 6.67248 8.70004 6.67248L10.964 9.02462L11 9.06205L11.036 9.02462L13.3 6.67248C13.5488 6.41391 13.9512 6.41391 14.2001 6.67248C14.4501 6.93211 14.45 7.35355 14.2 7.6132L14.2361 7.64787L14.2 7.6132L11.9362 9.96534ZM2.8 10C2.8 5.2996 6.48007 1.47859 11 1.47859C15.5199 1.47859 19.2 5.2996 19.2 10C19.2 14.7004 15.5199 18.5214 11 18.5214C6.48007 18.5214 2.8 14.7004 2.8 10ZM4.075 10C4.075 13.9645 7.17993 17.1929 11 17.1929C14.8201 17.1929 17.925 13.9645 17.925 10C17.925 6.03551 14.8201 2.80716 11 2.80716C7.17993 2.80716 4.075 6.03551 4.075 10Z" fill="currentColor" stroke="white" stroke-width="0.1"/>
      </g>
      <defs>
      <clipPath id="clip0_129_23945">
      <rect width="20" height="20" fill="white"/>
      </clipPath>
      </defs>
      </svg>
      `);

    }
    if (this.notApplicable) {
      // NOSONAR: bypassing Angular's html sanitization
      this.svgContent = this.sanitizer.bypassSecurityTrustHtml(`<svg width="22" height="20" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_129_23950)">
      <path d="M2.8 10C2.8 5.2996 6.48007 1.47859 11 1.47859C15.5199 1.47859 19.2 5.2996 19.2 10C19.2 14.7004 15.5199 18.5214 11 18.5214C6.48007 18.5214 2.8 14.7004 2.8 10ZM4.075 10C4.075 13.9645 7.17993 17.1929 11 17.1929C14.8201 17.1929 17.925 13.9645 17.925 10C17.925 6.03551 14.8201 2.80716 11 2.80716C7.17993 2.80716 4.075 6.03551 4.075 10Z" fill="currentColor" stroke="white" stroke-width="0.1"/>
      <line x1="16.5303" y1="4.53033" x2="5.53033" y2="15.5303" stroke="currentColor" stroke-width="1.5"/>
      </g>
      <defs>
      <clipPath id="clip0_129_23950">
      <rect width="22" height="20" fill="white"/>
      </clipPath>
      </defs>
      </svg>
      `);

    }

  }

  loadSvgFile(filePath: string): void {
    // Make an HTTP request to fetch the SVG file content
    this.fetchSvgFile(filePath).then(svgContent => {
      // Sanitize and set the SVG content
      this.svgContent = this.sanitizer.bypassSecurityTrustHtml(svgContent);
    }).catch(error => {
      console.error('Error loading SVG file:', error);
    });
  }

  fetchSvgFile(filePath: string): Promise<string> {
    // Replace this with your own HTTP request logic
    return new Promise<string>((resolve, reject) => {
      // For demonstration purposes, using a simple fetch
      fetch(filePath)
        .then(response => response.text())
        .then(svgContent => resolve(svgContent))
        .catch(error => reject(error));
    });
  }
}
