import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { moveItemInArray } from '@angular/cdk/drag-drop';

@Component({
  selector: 'app-priority-matrix',
  templateUrl: './priority-matrix.component.html',
  styleUrls: ['./priority-matrix.component.scss']
})
export class PriorityMatrixComponent {

  displayData: any;
  index: any

  constructor(
    public dialogRef: MatDialogRef<PriorityMatrixComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any
  ) {
    this.displayData = data;
  }

  drop(event: any) {
    moveItemInArray(this.displayData.items, event.previousIndex, event.currentIndex);
  }

  performAction(): void {
    this.dialogRef.close(this.displayData.items);
  }

}
