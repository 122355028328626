import { Injectable } from '@angular/core';
import { Observable, from } from 'rxjs';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpErrorResponse,
} from '@angular/common/http';
import { switchMap, tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { AuthService } from 'src/app/auth/auth.service';
import { config } from 'src/app/shared/constants';

@Injectable()
export class RequestInterceptor implements HttpInterceptor {
  private baseUrl: string = environment.baseURL;

  constructor(
    private router: Router,
    private auth: AuthService
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return from(this.auth.refreshToken()).pipe(
      switchMap((user:any) => {
        let cloneReq;
        if (user != null) {
          let queryParams = '';
          let url = req.url;
          let token = user;
          if (req.url.includes(config.magicLink.token)) {
            const { token: newToken, url: newUrl, queryParams: newQueryParams } = this.createHeaderReq(req);
            token = newToken;
            url = newUrl;
            queryParams = newQueryParams;
          }
          const headers = this.setHeaders(token, req)

          cloneReq = req.clone({ headers: headers, url: `${this.baseUrl}${url}${queryParams ? `?${queryParams}` : ''}` });
        } else {
          if (req.url.includes(config.magicLink.token)) {
            const { token, url, queryParams } = this.createHeaderReq(req);
            const headers = this.setHeaders(token, req)
            cloneReq = req.clone({ headers: headers, url: `${this.baseUrl}${url}${queryParams ? `?${queryParams}` : ''}` });
          } else {
            cloneReq = req.clone({ url: `${this.baseUrl}${req.url}` });
          }
        }

        // Pass on the cloned request instead of the original request.
        return next.handle(cloneReq).pipe(
          tap(
            () => {
              // this.idleTimeService.resetIdleTimer();
            },
            (err) => {
              const loginRoute = '/auth/login';
              if (err instanceof HttpErrorResponse && (err.status === 401)) {
                this.auth.SignOut();
                this.router.navigate([loginRoute]);
              }
            },
          ),
        );
      })
    );
  }

  splitQueryParams(queryParams: any) {
    const params = queryParams.includes(config.magicLink.queryParam) ? queryParams.split(config.magicLink.queryParam) : [];
    return params
  }

  getToken(token: string) {
    let queryToken: any = '';
    if(this.splitQueryParams(token).length>0){
      return queryToken = this.splitQueryParams(token)[0]
    }
  }

  setHeaders(token: any, req: any) {
    return req.headers.set(
      'Authorization',
      `Bearer ${token}`
    );
  }

  setQueryParams(req: any) {
    const splitUrl = this.splitQueryParams(req.url)
    if (splitUrl.length > 0) {
      return splitUrl[1];
    }
  }

  createHeaderReq(req: any): { token: string, url: string, queryParams: string}  {
    let token = req.url.split(config.magicLink.token)[1];
    token = this.getToken(token);
    const url = req.url.split(`/${config.magicLink.token}`)[0];
    const queryParams = this.setQueryParams(req)
    return {token, url, queryParams}
  }
}
