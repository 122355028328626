
<div class="scroller">
  <div *ngFor="let notification of sortedGroups">
    <p class="notification-day">{{ 'abc' }}</p>
      <div class="notifications__list">

        <div *ngFor="let item of notification" class="notifications__item notifications__item--system-generated">
          <div class="notifications__content">
            <div class="system-gen-head-wrap">
              <h6 class="generated-heading">New Order</h6>
            </div>
            <p class="generated-text" *ngIf="item.notification_type.name === 'Assign Reviewer'"><b>Order {{item.order.order_number}} has been assigned to you.</b></p>

          </div>
          <div class="notifications__time-wrap">
            <p class="notifications__time">{{item.created_on  |  localDate:'MM/dd/yyyy h:mma'}}</p>
            <div [class.notifications__unread]="item.read_status ? false : true"></div>
          </div>
        </div>

      </div>
  </div>
</div>
<div *ngIf="sortedGroups.length === 0">{{noResultsFound}}</div>
