import { Component } from '@angular/core';
import { AuthService } from 'src/app/auth/auth.service';

@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.scss']
})
export class PageNotFoundComponent {
  constructor(private authService: AuthService) {

  }

  logoutUser() {
    this.authService.logoutFromBE().then(() => {
      this.authService.SignOut();
    })
  }
}
