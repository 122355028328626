<div class="revision-request">
  <div class="revision-request__headline-wrap">
    <h5>{{ stepForRevision === 1 ? 'Message for client' : 'Revision Message' }}</h5>
  </div>
  <div class="revision-request__main">
    <div class="revision-request__revison-greetings" *ngIf="stepForRevision === 0">
      <p>Hello,</p>
      <p>
        Thank you for the report. After review, the following revisions were found. Please address
        and upload the revised report as soon as possible.
      </p>
      <div class="revision-request__revision-msg-box">
        <ol>
          <ng-container *ngIf="originalFindingsData && originalFindingsData.length > 0">
            <ng-container *ngFor="let finding of originalFindingsData; let i = index">
              <ng-container *ngIf="finding?.review_finding_status?.name === findingStatus.REJECTED">
                <li>
                  <span>{{ finding?.finding_text1 }}</span>
                  <div class="comment">
                    <div><strong>Comment: </strong></div>
                    <div><span class="italic">"{{ finding?.comment }}"</span></div>
                  </div>
                </li>
              </ng-container>
            </ng-container>
          </ng-container>
        </ol>
      </div>
    </div>

    <div class="revision-request__revison-greetings" *ngIf="stepForRevision === 1">
      <p>Hello Team,</p>
      <p>
        A revision request has been sent to the appraiser regarding this order. Once we receive the
        revised report or communication from the appraiser we will update accordingly.
      </p>
      <p>Thank you</p>
    </div>
  </div>
</div>

<div [hidden]="true" class="sendMessagetoAppraiser">
  <p>Hello,</p>
  <p>
    Thank you for the report. After review, the following revisions were found. Please address and
    upload the revised report as soon as possible.
  </p>
  <ol>
    <ng-container *ngIf="originalFindingsData && originalFindingsData.length > 0">
      <ng-container *ngFor="let finding of originalFindingsData; let i = index">
        <ng-container *ngIf="finding?.review_finding_status?.name === findingStatus.REJECTED">
          <li style="list-style: auto;padding-bottom: 10px;">
            <span>{{ finding?.finding_text1 }}</span>
            <div class="comment" style="display: flex;padding-top: 20px;margin-left: 20px;">
              <div><strong>Comment: </strong></div>
              <div><span class="italic"style="font-style: italic">"{{ finding?.comment }}"</span></div>
            </div>
          </li>
        </ng-container>
      </ng-container>
    </ng-container>
  </ol>
</div>

<div [hidden]="true" class="sendMessagetoClient">
  <p>Hello Team,</p>
  <p>
    A revision request has been sent to the appraiser regarding this order. Once we receive the
    revised report or communication from the appraiser we will update accordingly.
  </p>
  <p>Thank you</p>
</div>
