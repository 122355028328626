import { Component, EventEmitter, Input, Output, SimpleChanges, ViewChild } from '@angular/core';
import { TableColumn } from './table-column.interface';
import { TableActions, TableColumnTypes } from '../../enums/table';
import { noResultAvailable } from '../../errors/error-messages';
import { EventEmitterService } from 'src/app/core/services/event-emitter.service';
import { OrderStatuses } from '../../enums/orders';
import { ActivatedRoute, Router, UrlSegment } from '@angular/router';
import { Observable, map } from 'rxjs';
import { UserRole } from '../../enums/user';
import { UserConfigurationService } from 'src/app/core/services/user-configuration.service';
import { ActionPermissions } from '../../constants/permissions';
import { MatSort } from '@angular/material/sort';

@Component({
  selector: 'app-global-mat-table',
  templateUrl: './global-mat-table.component.html',
  styleUrls: ['./global-mat-table.component.scss']
})
export class GlobalMatTableComponent {
  columnType = TableColumnTypes;
  _dataSource: any[] = [];
  public displayedColumns: string[];
  @Input() columns: TableColumn[];
  @Input() resetSorting: boolean;
  filters = {
    sort_by: '',
    sort_order: '',
  }

  noResultAvailableTxt = noResultAvailable;
  permission = ActionPermissions;

  @Input() pageInfo: any;

  @Input() totalRecords: any;

  @Input() listType: any;

  @Input() set dataSource(data: any[]) {
    this.setDataSource(data);
  }

  @Output() actionPerformedOnTable = new EventEmitter<any>();
  @Output() resetSort = new EventEmitter<boolean>();

  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private eventEmitterService: EventEmitterService,
    private route: ActivatedRoute,
    private router: Router,
    public userConfig: UserConfigurationService) {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.columns && this.columns.length > 0) {
      this.displayedColumns = this.columns.map((tableColumn: TableColumn) => tableColumn.field);
    }
    if('resetSorting' in changes && this.resetSorting) {
      this.resetSortIcon();
    }
  }

  resetSortIcon() {
    this.sort.sort({ id: '', start: 'asc', disableClear: false });
    this.filters.sort_by = '';
    this.filters.sort_order = '';
  }

  setDataSource(data: any) {
    if (data) {
      this._dataSource = data;
    }
  }

  statusOnChange(event: any, rule: any) {
    const data = rule;
    data.value = event.target.checked;
    data.actionPerformed = TableActions.TOGGLE;
    this.actionPerformedOnTable.emit(data);
  }

  onPageChange(event: any): void {
    const data = event;
    data.actionPerformed = TableActions.PAGINATE;
    this.actionPerformedOnTable.emit(data);
  }

  sortData(event: any) {
    const data = event;
    this.filters.sort_by = event.active;
    this.filters.sort_order = event.direction;
    data.actionPerformed = TableActions.SORT;
    this.resetSort.emit(false);
    this.actionPerformedOnTable.emit(data);
  }

  actionMenuClick(event: any, action: string) {
    const data = event;
    data.actionPerformed = action;
    this.actionPerformedOnTable.emit(data);
    this.eventEmitterService.emitTableActionsOnClick(data);
  }

  redirectToBasicNSRule(ruleID: string): void {
    this.router.navigate(['rules/expression-builder'], { queryParams: { ruleId: ruleID, isEdit: true} });
  }

  public getRedirectUrl(order: any): Observable<any> {
    return this.route.url.pipe(
      map((segments: UrlSegment[]) => {
        if (order.order_status.status === OrderStatuses.COMPLETED ||
            order.order_status.status === OrderStatuses.REVISION_REQUESTED ||
            order.order_status.status === OrderStatuses.ARCHIVED) {
          return {params: segments, queryParams: {reviewId: order.reviews[0].id, isPreview: true}}
        } else {
          return {params: segments, queryParams: {reviewId: order.reviews[0].id}}
        }
      })
    );
  }

}
