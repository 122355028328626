import { Component, Input } from '@angular/core';
import { OrderService } from '../../order.service';
import { ToastrService } from 'ngx-toastr';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { AbstractControl, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { ALLOWED_FILE_TYPES, DOCUMENT_TYPES } from 'src/app/shared/helpers';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { config } from 'src/app/shared/constants';
import { message } from 'src/app/shared/constants/alerts_messages';

@Component({
  selector: 'app-order-review-documents',
  templateUrl: './order-review-documents.component.html',
  styleUrls: ['./order-review-documents.component.scss']
})
export class OrderReviewDocumentsComponent {
  uploadDocumentForm: FormGroup;
  typeOptions: string[] = DOCUMENT_TYPES;
  filteredDocTypes: string[];
  displayedColumns: string[] = ['name', 'type', 'created_at'];
  dataSource: any = [];
  dataSourceAdditional: any = [];
  file_store: any;
  fileSizeCheck = false;
  fileSizeInMB = config.maxFileSizeMB;
  fileTypeCheck = false;
  selectedTab = 0;
  fetchingDocs = false;
  isUploadDocument = false;
  allowedTypes: any;
  @Input() data: any;
  @Input() documents: any;
  @Input() isPreview: boolean;

  constructor(
    private orderService: OrderService,
    private toastr: ToastrService,) {
    this.uploadDocumentForm = new FormGroup({
      file: new FormControl('', [Validators.required]),
      type: new FormControl('', [Validators.required, this.validateDocumentType]),
    });
  }

  ngOnChanges() {
    if(this.documents && this.documents.length > 0) {
      this.dataSource = this.getUniqueDocs(this.documents);
    }
    this.filteredDocTypes = this.typeOptions;
    this.allowedTypes = ALLOWED_FILE_TYPES;
  }

  getDocuments(slug?: boolean, fetchDoc?: boolean): void {
    if (this.data?.revisionNumber && this.data?.orderNumber && this.dataSourceAdditional.length === 0 || fetchDoc) {
      this.orderService.getReviewDocuments(this.data?.revisionNumber, this.data?.orderNumber, this.data?.token, slug)?.subscribe((documents) => {
        if (documents && slug && documents.docs && documents.docs.length > 0) {
          this.dataSourceAdditional = this.getUniqueDocs(documents?.docs);
        }
      })
    }
  }

  getUniqueDocs(doucumentsList: any): any[] {
    return doucumentsList.reduce((acc: any, item: any) => {
      if (!acc.some((existingItem: any) => existingItem.name === item.name)) {
        acc.push(item);
      }
      return acc;
    }, []);
  }

  downloadFile(fileName: string): void {
    this.orderService.downloadReviewDocumnets(fileName).subscribe(
      (data) => {
        window.open(data.url, '_blank');
      },
      (error) => {
        this.toastr.error(error.error.detail);
      }
    );
  }

  tabChanged(tabChangeEvent: MatTabChangeEvent): void {
    if (tabChangeEvent.index === 0) {
      this.displayedColumns = ['name', 'type', 'created_at'];
      this.isUploadDocument = false;
      this.selectedTab = 0;
    } else if (tabChangeEvent.index === 1) {
      this.displayedColumns = ['name', 'type', 'date_loaded'];
      this.selectedTab = 1;
      this.getDocuments(true);
    }
  }

  fetchDocuments() {
    this.fetchingDocs = true;
    if (this.data?.orderNumber) {
      this.orderService.getAdditionalDocuments(this.data?.orderNumber, this.data?.token).subscribe((data) => {
        this.fetchingDocs = false;
        if (data && data.length > 0) {
          this.getDocuments(true, true);
        } else {
          this.toastr.error("No new document found");
        }
      })
    }
  }

  validateDocumentType: ValidatorFn = (control: AbstractControl) => {
    const validDocumentTypes = DOCUMENT_TYPES;
    const selectedType = control.value;

    if (selectedType && !validDocumentTypes.includes(selectedType)) {
      return { invalidDocumentType: true };
    }

    return null;
  }

  filterOptionsDocType(value: string) {
    if (value) {
      const filteredTypes = this.typeOptions.filter((type: string) =>
        type.toLowerCase().startsWith(value.toLowerCase())
      );

      this.filteredDocTypes = filteredTypes;

      const selectedType = this.uploadDocumentForm?.get('type')?.value;
      if (selectedType && !filteredTypes.includes(selectedType)) {
        this.uploadDocumentForm.get('type')?.setErrors({ invalidDocumentType: true });
      } else {
        this.uploadDocumentForm.get('type')?.setErrors(null);
      }
    } else {
      this.filteredDocTypes = this.typeOptions;
    }
  }

  handleFileInputChange(l: FileList | null) {
    const allowedFileTypes = ALLOWED_FILE_TYPES;
    if (l === null) {
      this.file_store = null;
      this.fileSizeCheck = false;
      this.fileTypeCheck = false;
      const fileControl = this.uploadDocumentForm.get('file');
      if (fileControl) {
        fileControl.patchValue('');
      }
      return;
    }

    const validFiles = Array.from(l).filter(file => {
      const fileExtension = file.name.split('.').pop()?.toLowerCase();
      return fileExtension && allowedFileTypes.includes(fileExtension);
    });
    this.file_store = validFiles;
    this.onFileChange(this.file_store, 1);

    if (l.length && validFiles.length === 0) {
      this.fileSizeCheck = false;
      this.fileTypeCheck = true;
      return;
    } else {
      this.fileSizeCheck = false;
      this.fileTypeCheck = false;
    }

    this.file_store = validFiles;
    if (validFiles.length) {
      const f = validFiles[0];
      if (f.size > config.maxFileSize) {
        this.fileSizeCheck = true;
        return;
      } else {
        this.fileSizeCheck = false;
      }

      const count = validFiles.length > 1 ? `(+${validFiles.length - 1} file)` : '';
      const fileControl = this.uploadDocumentForm.get('file');
      if (fileControl) {
        fileControl.patchValue(`${f.name}${count}`);
      }
    }
  }

  onFileChange(files: any, type: any) {
    if (files[0]) {
      const filesAmount = files.length;
      for (let i = 0; i < filesAmount; i++) {
        const reader = new FileReader();
        reader.readAsDataURL(files[i]);
      }
    }
  }

  optionSelected(event: MatAutocompleteSelectedEvent, formControlName: string): void {
    this.uploadDocumentForm.controls[formControlName].setValue(event.option.viewValue);
  }

  onUploadDocs() {
    this.isUploadDocument = true;
  }

  onCancelAdditionalDocument() {
    this.isUploadDocument = false;
    this.file_store = [];
    this.uploadDocumentForm.reset();
    this.fileSizeCheck = false;
    this.fileTypeCheck = false;
  }

  onAddAdditionalRecord(doc: any) {
    const param: FormData = new FormData();
    for (let fileStore of this.file_store) {
      param.append('file', <File>fileStore);
    }

    this.orderService.uploadDocument(this.data?.review_id, doc.type, param, true).subscribe(
      (response) => {
        if (response) {
          this.toastr.success(message.uploadDocument);
          this.onCancelAdditionalDocument();
          this.getDocuments(true, true);
        }
      },
      (error) => {
        this.toastr.error(error.error.detail);
      }
    );
  }

}
