import { Injectable } from '@angular/core';
import { ToastrService, ActiveToast } from 'ngx-toastr';


@Injectable()
export class ToastService {
  toastTimeout = 5000;

  constructor(private toast: ToastrService) {}

  // eslint-disable-next-line
  success(message: string, title: string = 'Success!'): ActiveToast<any> {
    return this.toast.success(message, title, {
      timeOut: this.toastTimeout,
    });
  }

  // eslint-disable-next-line
  error(message: string, enableHtml: boolean = false): ActiveToast<any> {
    return this.toast.error(message, 'Error', {
      timeOut: this.toastTimeout,
      enableHtml,
    });
  }

  warning(message: string): void {
    this.toast.warning(message, 'Warning', {
      timeOut: this.toastTimeout,
    });
  }

  info(message: string): void {
    this.toast.info(message, 'Info!', {
      timeOut: this.toastTimeout,
    });
  }
}
