<div mat-dialog-title class="layout__card-header">
  <h3>Layout Preference</h3>
  <mat-icon fontSet="material-icons-outlined" mat-dialog-close>highlight_off</mat-icon>
</div>
<div mat-dialog-content>
  <div class="layout__card-body">
    <p class="layout-text">Select your prefered layout for order reviewing:</p>
    <mat-radio-group [(ngModel)]="data.isRightSidePreference" class="svg-placeholder">
      <div class="layout-column">
        <app-svg [svgPath]="'../../../../assets/images/left-side.svg'"></app-svg>
        <mat-radio-button [value]="false">The findings panel aligned to your left & reports on the right.</mat-radio-button>
      </div>
      <div class="layout-column">
        <app-svg [svgPath]="'../../../../assets/images/right-side.svg'"></app-svg>
        <mat-radio-button [value]="true">The findings panel aligned to your right & reports on the left.</mat-radio-button>
      </div>
    </mat-radio-group>
  </div>
</div>
<div mat-dialog-actions class="layout__actions">
  <button class="layout__btn-outlined" mat-dialog-close>Cancel</button>
  <button class="layout__btn-filled" (click)="submit()">Save</button>
</div>
