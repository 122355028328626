import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { Router } from '@angular/router';
import { map } from 'rxjs';

@Injectable()
export class PublicGuard {
  constructor(
    private afAuth: AngularFireAuth,
    private router: Router,
  ) {}

  canActivate() {

    return this.afAuth.authState.pipe(
      map((user) => {
        if (user) {
          // User is authenticated, allow access to the route
          this.router.navigate(['/']);
          return false;
        } else {
          return true;
        }
      })
    );
  }
}
