import { Component, EventEmitter, Input, Output } from '@angular/core';
import { OrderService } from '../../order.service';

@Component({
  selector: 'app-sub-forms-view',
  templateUrl: './sub-forms-view.component.html',
  styleUrls: ['./sub-forms-view.component.scss'],
  outputs: ['formChange']
})
export class SubFormsViewComponent {
  reviewFormsList: any = [];
  selectedValue: any;
  selectedIndex: -1;
  @Input() reviewId: string;
  @Input() findingForm: any;
  @Output() formChange = new EventEmitter();
  @Output() filteredForms = new EventEmitter();


  constructor(private orderService: OrderService) { }

  ngOnInit(): void {
    this.getReviewSubForms();
  }

  ngOnChanges() {
    if(this.findingForm && this.findingForm.length > 0) {
      this.selectedValue = this.findingForm[0];
    }
  }

  getFormOnChange(value: any) {
    this.selectedValue = value;
    this.formChange.emit(this.selectedValue);
  }

  getReviewSubForms(): void {
    this.orderService.getSubForms(this.reviewId).then(
      (response: any) => {
        if (response) {
          this.reviewFormsList = this.getValidReviewForms(response.review_forms);
          this.filteredForms.emit(this.reviewFormsList);
        }
      }
    );
  }

  getValidReviewForms(review_forms: any): any[] {
    const uniqueItems: any[] = [];

    return review_forms.reduce((acc: any, item: any) => {
      const isSpecialItem =
        item.name === '1004_05UAD' ||
        item.name === '1073_05AUAD' ||
        item.name === '1004C_05' ||
        item.name === '1004D_05' ||
        item.name === '2055_05UAD' ||
        item.name === '1004MC' ||
        item.name === '1004MC_2009' ||
        item.name === '456_sub_form' ||
        item.name === '1025_05' ||
        item.name === '789_sub_form';

      const isNotDuplicate = !isSpecialItem || !uniqueItems.some(existingItem => existingItem.name === item.name);

      if (isNotDuplicate && item.form_id !== null) {
        uniqueItems.push(item);
        acc.push(item);
      }

      return acc;
    }, []);
  }

  previousValue() {
    const currentIndex = this.reviewFormsList.indexOf(this.selectedValue);
    if (currentIndex === 0 || currentIndex === -1) {
      return;
    }
    const previousIndex = currentIndex > 0 ? currentIndex - 1 : this.reviewFormsList.length - 1;
    this.selectedValue = this.reviewFormsList[previousIndex];
    this.formChange.emit(this.selectedValue);
  }

  nextValue() {
    const currentIndex = this.reviewFormsList.indexOf(this.selectedValue);
    if (this.reviewFormsList.length - 1 === currentIndex) {
      return;
    }
    const nextIndex = currentIndex < this.reviewFormsList.length - 1 ? currentIndex + 1 : 0;
    this.selectedValue = this.reviewFormsList[nextIndex];
    this.formChange.emit(this.selectedValue);
  }

  getValue(item: string) {
    this.selectedIndex = this.reviewFormsList.indexOf(this.selectedValue);
    return item;
  }
}
