<div mat-dialog-title class="add-client__card-header">
  <h3>{{isEditMode ? 'Update' : 'Add'}} {{ruleEngine.MANUAL_FINDING_GEOUPS}}</h3>
  <mat-icon fontSet="material-icons-outlined" mat-dialog-close
      data-test-id="add-client-close">highlight_off</mat-icon>
</div>
<form [formGroup]="addManualfindingGroup">
  <div mat-dialog-content style="display: flex;">
      <div class="add-client__card">
          <div class="add-client__card-body">
            <mat-form-field appearance="outline">
              <mat-label>MFG Code</mat-label>
              <input matInput type="text" placeholder="MFG Code..." formControlName="mfg_code"
                  (focusout)="sharedService.removeSpaces(addManualfindingGroup.get('mfg_code'))"
                  data-test-id="add-rule-name" autocomplete="off" autocomplete="new-password">
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>MFG Description</mat-label>
            <textarea matInput type="text" rows="3" placeholder="MFG Description..." formControlName="description"
                (focusout)="sharedService.removeSpaces(addManualfindingGroup.get('description'))"
                data-test-id="add-rule-desc"></textarea>
          </mat-form-field>
          </div>
      </div>
  </div>
  <div mat-dialog-actions class="add-client__actions">
      <button type="button" class="add-client__btn-outlined" mat-dialog-close data-test-id="add-rule-close">Cancel</button>
      <button type="button" class="add-client__btn-filled" [disabled]="!addManualfindingGroup.valid"
          (click)="onAddManualfindingGroup(addManualfindingGroup.value)" data-test-id="add-rule-save">{{isEditMode ? 'Update' : 'Add'}}</button>
  </div>
</form>
