<div class="sub-forms">
  <button class="left-tooltip navigation-btn" nan-tooltip="Go to previous form." placement="top" edge-position="left"
    (click)="previousValue()"
    [ngClass]="{'disabled': selectedIndex === -1 || selectedIndex === 0}">
    <app-svg [svgPath]="'../../../../assets/images/arrow-left-circle.svg'"></app-svg>
  </button>
  <div class="sub-forms__form-wrapper">
    <label>Current Form:</label>
    <mat-form-field class="rules__select" appearance="outline" subscriptSizing="dynamic">
      <mat-select [value]="selectedValue" (selectionChange)="getFormOnChange($event.value)" class="select-wrapper">
        <mat-option #option *ngFor="let item of reviewFormsList" [value]="getValue(item)">{{item.name}} {{item.page_title}}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <button class="right-tooltip navigation-btn" nan-tooltip="Go to next form." placement="top" edge-position="right"
    (click)="nextValue()"
    [ngClass]="{'disabled': selectedIndex === reviewFormsList.length - 1}">
    <app-svg [svgPath]="'../../../../assets/images/arrow-right-circle.svg'"></app-svg>
  </button>
</div>
