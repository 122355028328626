import { ChangeDetectorRef, Component, ElementRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { OrderService } from '../../order.service';
import { config } from 'src/app/shared/constants/config';
import { ReviewDocument } from '../../orders.interface';

@Component({
  selector: 'app-order-preview',
  templateUrl: './order-preview.component.html',
  styleUrls: ['./order-preview.component.scss']
})
export class OrderPreviewComponent {
  orderId: string;
  orderNo: string;
  token: string;
  revisionNumber: number;
  orderStatus: string;
  documents: ReviewDocument[] = [];
  pdfUrl = '';
  viewDocumentOnly = true;

  constructor(private route: ActivatedRoute,
    private orderService: OrderService,
    public cdRef: ChangeDetectorRef,
    public elementRef: ElementRef) { }



  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.orderId = params['orderId'];
      this.token = params['token'];
      this.getOrderDetails();
    });
  }

  getOrderDetails(): void {
    this.orderService.getOrderDetail(this.orderId, this.token).subscribe(
      (orderDetail) => {
        this.orderNo = orderDetail.order_number;
        this.documents = orderDetail?.reviews[0].review_document;
        this.orderStatus = orderDetail.order_status.status;
        this.revisionNumber = orderDetail.reviews[0].review_version_number;
        if (this.documents) {
          for (let i = this.documents.length - 1; i >= 0; i--) {
            const extension = config.checkFileExtension.exec(this.documents[i].name);
            if (extension && extension[1] === 'pdf') {
              this.downloadFile(this.documents[i].name);
              break;
            }
          }
        }
      });
  }

  downloadFile(fileName: string): void {
    this.orderService.downloadReviewDocumnets(fileName).subscribe(
      (data) => {
        this.pdfUrl = data.url;
      }
    );
  }

}
