<div class="scrollable-body">
  <div *ngFor="let revision of revisionsData; let i = index">
    <div class="revision-wrapper">
      <div class="revision-wrapper__card-header">
        <div class="revision-wrapper__card-header__revision-number">
          <div class="revision-iteration">{{revisionsData.length - i}}</div>
        </div>
        <div class="revision-wrapper__card-header__recepiant-wrapper">
          <div class="recepiant-main">
            <div class="recepiant-name">
              <span>To: </span><strong>{{revision.revision_sent_to_appraiser?.first_name}} {{revision.revision_sent_to_appraiser?.last_name}}</strong> ({{revision.revision_sent_to_appraiser?.roles[0]?.role.name ? revision.revision_sent_to_appraiser?.roles[0]?.role.name : 'Appraiser'}})
            </div>
            <div class="recepiant-date">
              <div>{{revision.created_on | localDate:'hh:mm a'}}</div>
              <div>{{revision.created_on | localDate:'MM/dd/yy'}}</div>
            </div>
          </div>
          <div class="recepiant-main">
            <div class="recepiant-name">
              <span>From: </span><strong>{{revision.revision_sent_by.first_name}} {{revision.revision_sent_by.last_name}}</strong> ({{revision.revision_sent_by?.roles[0]?.role.name}})
            </div>
            <div class="recepiant-type">
              Type: <span>{{revision.revision_type.name}}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="revision-wrapper__card-body" [ngClass]="{'trucateText': isTextTruncated[i]}" smartTruncate [description]="revision?.revision_message" (OnViewMore)="readMoreDescription($event, i)">
      </div>
    </div>
  </div>
  <div class="norevisions" *ngIf="revisionsData.length === 0">
    <app-svg [svgPath]="'../../../../assets/images/No revisions.svg'"></app-svg>
    <p>No revisions requested yet.</p>
  </div>
</div>
