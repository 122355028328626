<div class="error-container">
    <div class="error-div">
        <p class="error-bg">?</p>
        <p class="error-msg">OOPS! Something went wrong.</p>

    </div>
    <div class="logo-img">
        <img src="../../../assets/images/vonniqc-logo.svg" alt="" class="">
    </div>
    <p class="error-content">
        We are working on fixing the issue.
        In the meantime, try refreshing the page.
    </p>
</div>