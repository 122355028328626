import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-client-smart-link',
  templateUrl: './client-smart-link.component.html',
  styleUrls: ['./client-smart-link.component.scss']
})
export class ClientSmartLinkComponent {
  @Input() clientId: string;
  @Input() clientName: string;
  @Input() hoverView = false;

  constructor(private router: Router) {}

  navigateToClientDetails(id: string) {
    this.router.navigateByUrl(`client-profile/client-detail/${id}`);
  }
}
