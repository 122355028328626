import { ErrorHandler, Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

@Injectable()
export class ErrorHandlerService implements ErrorHandler {
  handleError(error: any): void {
    if (environment.showErrors) {
      // You can log or display the error here instead of creating a new ErrorHandler instance.
      console.error(error);
      // Additional actions, such as sending the error to a backend service, can be performed here.
    }
  }
}
