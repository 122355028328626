<div mat-dialog-title class="add-client__card-header">
  <h3>{{isEditMode ? 'Update' : 'Add'}} {{ruleEngine.RESTRICTED_WORDS}}</h3>
  <mat-icon fontSet="material-icons-outlined" mat-dialog-close
      data-test-id="add-client-close">highlight_off</mat-icon>
</div>
<form [formGroup]="addRestrictedWords">
  <div mat-dialog-content style="display: flex;">
      <div class="add-client__card">
          <div class="add-client__card-body">
              <mat-form-field appearance="outline">
                  <mat-label>Word</mat-label>
                  <input matInput type="text" placeholder="Word..." formControlName="word"
                      (focusout)="removeSpaces(addRestrictedWords.get('word'))"
                      data-test-id="add-rule-name">
              </mat-form-field>

              <mat-form-field appearance="outline">
                <mat-label>Category</mat-label>
                <input matInput [matAutocomplete]="autoCategory" placeholder="Category" formControlName="category_id"
                autocomplete="off" autocomplete="new-password" (ngModelChange)="filterOptions(addRestrictedWords.get('category_id')?.value)"
                  data-test-id="create-restricted-word">
                <mat-icon matSuffix>keyboard_arrow_down</mat-icon>
                <mat-autocomplete #autoCategory="matAutocomplete" [displayWith]="getTitle.bind(this)">
                  <mat-option *ngFor="let category of filteredCategories" [value]="category.id">
                    <span>{{ category.category }}</span>
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field>
          </div>
      </div>
  </div>
  <div mat-dialog-actions class="add-client__actions">
      <button type="button" class="add-client__btn-outlined" mat-dialog-close data-test-id="add-rule-close">Cancel</button>
      <button type="button" class="add-client__btn-filled" [disabled]="!addRestrictedWords.valid"
          (click)="onAddRestrictedWords(addRestrictedWords.value)" data-test-id="add-rule-save">{{isEditMode ? 'Update' : 'Add'}}</button>
  </div>
</form>
