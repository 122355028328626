import { Component, ViewChild, ElementRef, Input } from '@angular/core';

@Component({
  selector: 'app-pdf-viewer',
  templateUrl: './pdf-viewer.component.html',
  styleUrls: ['./pdf-viewer.component.scss'],
})
export class PdfViewerComponent {
  @ViewChild('viewer') viewer: ElementRef;
  @Input() pdfUrl: string;
  @Input() viewDocumentOnly = false;
  pdfDocUrl: string;

  ngOnChanges(): void {
    if (this.pdfUrl) {
      if(this.viewDocumentOnly) {
        this.pdfDocUrl = this.pdfUrl + '#toolbar&view=FitH&view=FitV';
      } else {
        this.pdfDocUrl = this.pdfUrl + '#toolbar=0';
      }
    }
  }
}
