// eslint-disable-next-line
export const config = {
  api: 'api/',
  version: 'v1/',
  apiBase: 'api/v1/',
  errorCode: 'auth/user-disabled',
  cookieExpiry: {
    min: 1,
    max: 365,
  },
  magicLink: {
    token: '@token=',
    queryParam: '?'
  },
  refreshTokenExpiry: 3300000, // milliseconds
  logoutTimer: 300, // seconds
  cookieExpiryWarning: 60,
  show: 'show',
  hide: 'hide',
  defaultDateFormat: 'MM-DD-YYYY',
  timeFormat: 'h:mm a',
  defaultDateTimeFormat: 'MM-DD-YYYY h:mm A',
  graphTitleFormat: 'dddd, MM/DD/YYYY',
  phoneFormat: '999 999 9999',
  mobileViewWidth: 768,
  tabletViewWidth: 1024,
  passwordMinLength: 8,
  initialListPageSize: 25,
  listPageSize: 25,
  maxPagesToShow: 5,
  pageSizeOptions: [25, 50, 75, 100],
  maxFileSize: 100000000,
  maxFileSizeMB: 100,
  month: 'mo',
  months: 'mos',
  year: 'yr',
  years: 'yrs',
  weekDays: ["Mon",
  "Tue",
  "Wed",
  "Thu",
  "Fri",
  "Sat",
  "Sun"],
  completeDays: {
    "Mon": 'Monday',
    "Tue": 'Tuesday',
    "Wed": 'Wednesday',
    "Thu": 'Thursday',
    "Fri": 'Friday',
    "Sat": 'Saturday',
    "Sun": 'Sunday'
  },
  passwordRegex: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
  phoneNumberRegex: /^\d{3} \d{3} \d{4}$/,
  zipCodeRegex: /^[0-9]{5}(?:-[0-9]{4})?$/,
  columnLimit: 3,
  emailRegex: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  checkFileExtension: /(?:\.([^.]+))?$/,
  maxNotesViewableLength: 700,
  tagRegex: /<[^>]*$/,
  openingTagRegex: /<[^>]*>/g,
  closingTagRegex: /<\/\w+>$/
};
