<div mat-dialog-title class="add-client__card-header">
  <h3>{{isEditMode ? 'Update' : 'Add'}} {{ruleEngine.NAN_STANDARD_RULES}}</h3>
  <mat-icon fontSet="material-icons-outlined" mat-dialog-close
      data-test-id="add-client-close">highlight_off</mat-icon>
</div>
<mat-progress-bar mode="indeterminate" *ngIf="expressions.length === 0"></mat-progress-bar>

<form [formGroup]="addNANStdRules">
  <div mat-dialog-content style="display: flex;">
      <div class="add-client__card">
          <div class="add-client__card-body">
              <mat-form-field appearance="outline">
                  <mat-label>Rule Code</mat-label>
                  <input matInput type="text" placeholder="Rule Code..." formControlName="code"
                      (focusout)="removeSpaces(addNANStdRules.get('code'))"
                      data-test-id="add-rule-name" autocomplete="off" autocomplete="new-password">
              </mat-form-field>

              <mat-form-field appearance="outline">
                <mat-label>Rule Description</mat-label>
                <textarea matInput type="text" rows="3" placeholder="Rule Description..." formControlName="description"
                    (focusout)="removeSpaces(addNANStdRules.get('description'))"
                    data-test-id="add-rule-desc"></textarea>
              </mat-form-field>

              <mat-form-field appearance="outline">
                <mat-label>Target Field</mat-label>
                <input matInput [matAutocomplete]="autoField" placeholder="Target Field" formControlName="target_field"
                  autocomplete="off" autocomplete="new-password" (ngModelChange)="filterOptions(addNANStdRules.get('target_field')?.value)"
                  data-test-id="create-target-field">
                <mat-icon matSuffix>keyboard_arrow_down</mat-icon>
                <mat-autocomplete #autoField="matAutocomplete" [displayWith]="getTitle.bind(this)">
                  <mat-option *ngFor="let field of filteredFields" [value]="field.friendly_name">
                    <span [title]="field.friendly_name">{{ field.friendly_name }}</span>
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field>

              <mat-form-field appearance="outline">
                <mat-label>Expression Search</mat-label>
                <input matInput [matAutocomplete]="autoExpression" placeholder="Expression Search" formControlName="expression_search"
                autocomplete="off" autocomplete="new-password" (ngModelChange)="filterOptionsExpression(addNANStdRules.get('expression_search')?.value)"
                  data-test-id="create-target-field">
                <mat-icon matSuffix>keyboard_arrow_down</mat-icon>
                <mat-autocomplete #autoExpression="matAutocomplete" [displayWith]="getTitleExpression.bind(this)" (optionSelected)="onSelectionExpression($event)" >
                  <mat-option *ngFor="let expression of filteredExpression" [value]="expression">
                    <span [title]="expression.operator ? expression.operator : expression.fieldName">{{ expression.operator ? expression.operator : expression.fieldName}}</span>
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field>

            <mat-form-field appearance="outline">
              <mat-label>Expression</mat-label>
              <textarea matInput #inputField
              (focus)="checkLastFocusField('expression')" formControlName="expressions"
              (focusout)="removeSpaces(addNANStdRules.get('expressions'))" rows="10"></textarea>
            </mat-form-field>


          </div>
      </div>
  </div>
  <div mat-dialog-actions class="add-client__actions">
      <button type="button" class="add-client__btn-outlined" mat-dialog-close data-test-id="add-rule-close">Cancel</button>
      <button type="button" class="add-client__btn-filled" [disabled]="!addNANStdRules.valid || expressions.length === 0"
          (click)="onAddNANStdRules(addNANStdRules.value)" data-test-id="add-rule-save">{{isEditMode ? 'Update' : 'Add'}}</button>
  </div>
</form>
