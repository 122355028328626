import { Directive, Inject, OnDestroy, AfterViewInit } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Directive({
  selector: '[appAddNonce]'
})
export class AddNonceDirective implements AfterViewInit, OnDestroy {
  private observer: MutationObserver;
  private nonce: any;

  constructor(@Inject(DOCUMENT) private document: Document) {
    // Get the nonce from the meta tag
    this.nonce = this.document.querySelector('meta[name="CSP_NONCE"]')?.getAttribute('content');
  }

  ngAfterViewInit(): void {
    if (this.nonce) {
      this.addNonceToExistingElements();
      this.startObserving();
    }
  }

  ngOnDestroy(): void {
    if (this.observer) {
      this.observer.disconnect();
    }
  }

  private addNonceToExistingElements(): void {
    const elements = this.document.querySelectorAll('style, script');
    elements.forEach(element => {
      element.setAttribute('nonce', this.nonce!);
    });
  }

  private startObserving(): void {
    this.observer = new MutationObserver(mutations => {
      mutations.forEach(mutation => {
        mutation.addedNodes.forEach(node => {
          if (node.nodeType === 1) { // Node.ELEMENT_NODE
            const element = node as HTMLElement;
            if (element.tagName.toLowerCase() === 'style' || element.tagName.toLowerCase() === 'script') {
              element.setAttribute('nonce', this.nonce!);
            }
          }
        });
      });
    });

    this.observer.observe(this.document.head, { childList: true });
  }
}
