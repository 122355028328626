<div class="order-message">
  <div class="order-message__header">
    <mat-tab-group mat-align-tabs="start">
      <mat-tab
        label="Messages"
        labelClass="notifications__tab"
        bodyClass="notifications__tab-content"
      >

        <div class="order-message__input-box">
          <input type="search" class="order-message__input" [disabled]="isPreview" placeholder="Search text" data-test-id="order-message-search-input" [(ngModel)]="searchTerm" (keyup.enter)="searchMessages()">
          <mat-icon fontSet="material-icons-outlined" data-test-id="order-message-search-btn" (click)="searchMessages()">search</mat-icon>
        </div>

        <app-chat
          [isPreview]="isPreview"
          [orderId]="orderId"
          [searchTerm]="searchTerm"
          [clientName]="clientName"
          [clientImage]="clientImage"
          [reviewer]="reviewer"
          [unAssigned]="unAssigned"
        ></app-chat>

      </mat-tab>
      <mat-tab
        label="History"
        labelClass="notifications__tab"
        bodyClass="notifications__tab-content"
      >
        <div class="notifications__list" *ngIf="orderHistory">
          <mat-accordion #accordian multi>
            <ng-container *ngFor="let key of getKeys().reverse(); let i = index">
              <mat-expansion-panel
                [expanded]="orderHistory[key].panelOpenState"
                class="revision"
                (opened)="onPanelOpened(key)"
                (closed)="onPanelClosed(key)"
                hideToggle="true"
              >
                <mat-expansion-panel-header>
                  <span class="revision-no">{{ key }}</span>
                  <div class="mat-expansion-panel-header-title">
                    <span>{{ getOrdinalRevision(key) }}</span>
                    <app-svg
                      *ngIf="!orderHistory[key].panelOpenState"
                      [svgPath]="'../../../../assets/images/plus-square.svg'"
                    ></app-svg>
                    <app-svg
                      *ngIf="orderHistory[key].panelOpenState"
                      [svgPath]="'../../../../assets/images/minus-square.svg'"
                    ></app-svg>
                  </div>
                </mat-expansion-panel-header>
                <ng-container *ngIf="orderHistory[key] && orderHistory[key].length > 0">
                  <div *ngFor="let notification of orderHistory[key]">
                    <ng-container
                      *ngIf="revisionAction.ORDER_CREATED === notification.action_performed"
                    >
                      <div
                        class="notifications__item notifications__item--system-generated notifications__item--active"
                      >
                        <div class="generated-heading">
                          Order {{ notification?.order?.order_number }} created by
                          <strong
                            >{{ notification?.from_users?.first_name }}
                            {{ notification?.from_users?.last_name }}</strong
                          ><strong>{{
                            notification?.from_users?.roles &&
                            notification?.from_users?.roles.length > 0
                              ? '(' + notification?.from_users?.roles[0]?.role?.name + ')'
                              : ''
                          }}</strong>
                        </div>
                        <p class="time">
                          {{ notification.created_on | localDate : 'M/d/yy, h:mm a' }}
                        </p>
                      </div>
                    </ng-container>

                    <ng-container
                      *ngIf="revisionAction.ORDER_ESCALATION === notification.action_performed"
                    >
                      <div
                        class="notifications__item notifications__item--system-generated notifications__item--active"
                      >
                        <div class="generated-heading">
                          Order escalated to
                          <strong
                            >{{ notification?.to_users?.first_name }}
                            {{ notification?.to_users?.last_name }}</strong
                          >
                          <strong>{{
                            notification?.to_users?.roles &&
                            notification?.to_users?.roles.length > 0
                              ? '(' + notification?.to_users?.roles[0]?.role?.name + ')'
                              : ''
                          }}</strong>
                          by
                          <strong
                            >{{ notification?.from_users?.first_name }}
                            {{ notification?.from_users?.last_name }}</strong
                          >
                          <strong>{{
                            notification?.from_users?.roles &&
                            notification?.from_users?.roles.length > 0
                              ? '(' + notification?.from_users?.roles[0]?.role?.name + ')'
                              : ''
                          }}</strong>
                        </div>
                        <p class="time">
                          {{ notification.created_on | localDate : 'M/d/yy, h:mm a' }}
                        </p>
                      </div>
                    </ng-container>

                    <ng-container
                      *ngIf="revisionAction.STATUS_UPDATED === notification.action_performed"
                    >
                      <div
                        class="notifications__item notifications__item--system-generated notifications__item--active"
                      >
                        <div class="generated-heading">
                          <span>Status updated to </span>
                          <app-order-status
                            [orderStatus]="notification?.new_status"
                          ></app-order-status>
                          by
                          <strong
                            >{{ notification?.from_users?.first_name }}
                            {{ notification?.from_users?.last_name }}</strong
                          >
                          <strong>{{
                            notification?.from_users?.roles &&
                            notification?.from_users?.roles.length > 0
                              ? '(' + notification?.from_users?.roles[0]?.role?.name + ')'
                              : ''
                          }}</strong>
                        </div>
                        <p class="time">
                          {{ notification.created_on | localDate : 'M/d/yy, h:mm a' }}
                        </p>
                      </div>
                    </ng-container>

                    <ng-container
                      *ngIf="revisionAction.ASSIGN_MYSELF === notification.action_performed"
                    >
                      <div
                        class="notifications__item notifications__item--system-generated notifications__item--active"
                      >
                        <div class="generated-heading">
                          <strong
                            >{{ notification?.to_users?.first_name }}
                            {{ notification?.to_users?.last_name }}</strong
                          >
                          <strong>{{
                            notification?.to_users?.roles &&
                            notification?.to_users?.roles.length > 0
                              ? '(' + notification?.to_users?.roles[0]?.role?.name + ')'
                              : ''
                          }}</strong
                          >assigned this order to himself
                        </div>
                        <p class="time">
                          {{ notification.created_on | localDate : 'M/d/yy, h:mm a' }}
                        </p>
                      </div>
                    </ng-container>

                    <ng-container
                      *ngIf="
                        revisionAction.NEW_REVIEWER_ASSIGNMENT === notification.action_performed
                      "
                    >
                      <div
                        class="notifications__item notifications__item--system-generated notifications__item--active"
                      >
                        <div class="generated-heading">
                          Order assigned to
                          <strong
                            >{{ notification?.to_users?.first_name }}
                            {{ notification?.to_users?.last_name }}</strong
                          >
                          <strong>{{
                            notification?.to_users?.roles &&
                            notification?.to_users?.roles.length > 0
                              ? '(' + notification?.to_users?.roles[0]?.role?.name + ')'
                              : ''
                          }}</strong>
                          by
                          <strong
                            >{{ notification?.from_users?.first_name }}
                            {{ notification?.from_users?.last_name }}</strong
                          >
                          <strong>{{
                            notification?.from_users?.roles &&
                            notification?.from_users?.roles.length > 0
                              ? '(' + notification?.from_users?.roles[0]?.role?.name + ')'
                              : ''
                          }}</strong>
                        </div>
                        <p class="time">
                          {{ notification.created_on | localDate : 'M/d/yy, h:mm a' }}
                        </p>
                      </div>
                    </ng-container>

                    <ng-container
                      *ngIf="revisionAction.NEW_REVISION_CREATED === notification.action_performed"
                    >
                      <div
                        class="notifications__item notifications__item--system-generated notifications__item--active"
                      >
                        <div class="generated-heading">
                          Revision created for {{ notification?.order?.order_number }} by
                          <strong
                            >{{ notification?.from_users?.first_name }}
                            {{ notification?.from_users?.last_name }}</strong
                          >
                          <strong>{{
                            notification?.from_users?.roles &&
                            notification?.from_users?.roles.length > 0
                              ? '(' + notification?.from_users?.roles[0]?.role?.name + ')'
                              : ''
                          }}</strong>
                        </div>
                        <p class="time">
                          {{ notification.created_on | localDate : 'M/d/yy, h:mm a' }}
                        </p>
                      </div>
                    </ng-container>
                  </div>
                </ng-container>
                <span class="bottom-revision revision-no">{{ key }}</span>
              </mat-expansion-panel>
            </ng-container>
          </mat-accordion>
        </div>

        <div class="notifications__placeholder" *ngIf="!orderHistory || getKeys().length === 0">
          <div class="notifications__placeholder-img">
            <app-svg [svgPath]="'../../../../assets/images/time.svg'"></app-svg>
          </div>
          <p class="notifications__placeholder-text">No history items yet.</p>
        </div>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>
