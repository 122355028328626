<div mat-dialog-title class="delete-appraisal__card-header">
  <h3>{{data.title}}</h3>
  <mat-icon fontSet="material-icons-outlined" mat-dialog-close>highlight_off</mat-icon>
</div>
<div mat-dialog-content>
  <div class="delete-appraisal__card-body">
    <p class="delete-appraisal-text">Select the type of appraisal you want to delete:</p>
    <mat-radio-group [(ngModel)]="type">
      <div class="column-view">
        <mat-radio-button *ngIf="data.canDeleteEAD" value="EAD">EAD</mat-radio-button>
        <mat-radio-button *ngIf="data.canDeleteUCDP" value="UCDP">UCDP</mat-radio-button>
      </div>
    </mat-radio-group>
  </div>
</div>
<div mat-dialog-actions class="delete-appraisal__actions">
  <button class="delete-appraisal__btn-outlined" mat-dialog-close>Cancel</button>
  <button class="delete-appraisal__btn-filled" (click)="delete()">Delete</button>
</div>
