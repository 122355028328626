import { Component, ElementRef, Inject, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ToastService } from 'src/app/core/services/toast.service';
import { RuleEngineList, RuleEngineListSingular } from 'src/app/shared/enums/checkList';
import { RulesService } from '../rules.service';
import { Subject } from 'rxjs/internal/Subject';
import { message } from 'src/app/shared/constants/alerts_messages';
import { SharedService } from 'src/app/shared/shared.service';

@Component({
  selector: 'app-create-nan-std-rules',
  templateUrl: './create-nan-std-rules.component.html',
  styleUrls: ['./create-nan-std-rules.component.scss'],
})
export class CreateNanStdRulesComponent {
  ruleEngine = RuleEngineList;
  addNANStdRules: FormGroup;
  submitted = false;
  operators: any[] = [];

  searchField = new Subject<KeyboardEvent>();
  searchOperator = new Subject<KeyboardEvent>();

  lastfocusedField = 'target_field';
  @ViewChild('inputField') inputField!: ElementRef<HTMLInputElement>;

  filteredFields: any;
  targetFields: any = [];

  filteredExpression: any;
  expressions: any = [];

  filters = {
    operators: true,
    fields: true,
    is_basic: false,
    cache: true,
  };
  isEditMode = false;
  searchedOperators: any[] = [];
  searchedFields: any[] = [];
  clientId: any;

  constructor(
    private toastr: ToastService,
    private rulesService: RulesService,
    private sharedService: SharedService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<any>,
  ) {
    this.addNANStdRules = new FormGroup({
      code: new FormControl('', [Validators.required]),
      description: new FormControl('', [Validators.required]),
      target_field: new FormControl('', [Validators.required]),
      expression_search: new FormControl(''),
      expressions: new FormControl('', [Validators.required]),
    });
    this.clientId = data.clientId;
    if (data?.data?.id) {
      this.addNANStdRules.patchValue(data.data);
      this.isEditMode = true;
    }
  }

  ngOnInit() {
    this.operatorsAndFields();
  }

  operatorsAndFields() {
    this.rulesService.getOperatorsAndFields(this.filters).then(
      (response) => {
        this.operators = response.operators;
        this.targetFields = response.fields.data;
        this.filteredFields = this.targetFields;
        this.getExpressions();
      },
      (err) => {
        this.toastr.error(err.message);
      },
    );
  }

  getExpressions() {
    this.searchedOperators = this.operators.map((operator) => {
      return {
        operator: operator.operation ? operator.operation : null,
        fieldName: null,
        xpath: null,
      };
    });
    this.searchedFields = this.targetFields.map((field: any) => {
      return {
        operator: null,
        fieldName: field ? field.friendly_name : null,
        xpath: field ? field.xpath : null,
      };
    });
    this.expressions = [...this.searchedOperators, ...this.searchedFields];
    this.filteredExpression = this.expressions;
    if (this.isEditMode) {
      this.addNANStdRules.get('target_field')?.setValue(this.data.data.target_field);
    }
  }

  getSelectedFieldOrOperator(fieldAndOperator: any) {
    const inputElement = this.inputField.nativeElement;
    const cursorPosition = inputElement.selectionStart || 0;

    const currentValue = inputElement.value;

    // Don't remove empty spaces
    const text = `${currentValue != '' ? ' ' : ''}x("${fieldAndOperator.xpath}")`;
    const textToAdd =
      fieldAndOperator && !fieldAndOperator.operator? text : ' ' + fieldAndOperator.operator + ' ';

    const newValue =
      currentValue.substring(0, cursorPosition) +
      textToAdd +
      currentValue.substring(cursorPosition);

    this.addNANStdRules.get('expressions')?.setValue(newValue);

    inputElement.selectionStart = inputElement.selectionEnd = cursorPosition + textToAdd.length;
    inputElement.focus();
  }

  checkLastFocusField(field: string) {
    this.lastfocusedField = field;
  }

  onAddNANStdRules(formValue: any): void {
    this.submitted = true;
    if (!this.addNANStdRules.valid) {
      return;
    }

    delete formValue.expression_search;

    const param: FormData = new FormData();
    param.append('rule', JSON.stringify(formValue));

    if (this.isEditMode) {
      this.updateNaNRule(param);
    } else {
      this.createNaNRule(param);
    }
  }


  createNaNRule(param: any) {
    this.rulesService.createNANStdRule(param).then(
      (response) => {
        this.toastr.success(`${RuleEngineListSingular.NAN_STANDARD_RULE} ${message.ruleCreated}`);
        this.dialogRef.close(true);
      },
      (error) => {
        this.toastr.error(error.error.detail);
      },
    );
  }

  updateNaNRule (param: any) {
    const slug = this.clientId ? `clients/nan-std-rules/${this.clientId}` : 'nan-std-rules/'
    this.sharedService.ruleEngineUpdateStatues(slug, param, this.data.data.id).subscribe(
      (response) => {
        this.toastr.success(`${RuleEngineListSingular.NAN_STANDARD_RULE} ${message.ruleUpdated}`);
        this.dialogRef.close(true);
      },
      (error) => {
        this.toastr.error(error.error.detail);
      },
    );
  }

  removeSpaces(control: any): void {
    const newValue = control.value.trim();
    control.setValue(newValue);
  }

  getTitle(fieldName: string) {
    const result = this.targetFields.find((category: any) => category.friendly_name === fieldName);
    return result ? `${result.xpath}` : '';
  }

  filterOptions(value: any) {
    if (value) {
      this.filteredFields = this.targetFields.filter(
        (options: any) =>
          options?.friendly_name?.toLowerCase().includes(value.toLowerCase()),
      );
    } else {
      this.filteredFields = this.targetFields;
    }
  }

  getTitleExpression(selectedOption: any) {
    if (selectedOption.operator) {
      const result = this.expressions.find(
        (option: any) => option.operator === selectedOption.operator,
      );
      return result ? `${result.operator}` : '';
    } else {
      const result = this.expressions.find(
        (option: any) => option.fieldName === selectedOption.fieldName,
      );
      return result ? `${result.fieldName}` : '';
    }
  }

  onSelectionExpression(event: any) {
    if (event) {
      this.getSelectedFieldOrOperator(event?.option?.value)
    }
  }

  filterOptionsExpression(value: any) {
    if (value) {
      const expression = this.expressions.filter(
        (options: any) =>
          options &&
          (options?.fieldName || options?.operator) &&
          (options?.fieldName?.toLowerCase().includes(value) ||
            options?.operator === value),
      );

      this.filteredExpression = [...expression];
    } else {
      this.filteredExpression = this.expressions;
    }
  }
}
