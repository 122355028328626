<div *ngIf="orderStatus === status.PROCESSING" class="chip chip--needs-review" title={{orderStatus}}>{{ orderStatus }}</div>

<div *ngIf="orderStatus === status.COMPLETED" class="chip chip--completed" title={{orderStatus}}>{{ orderStatus }}</div>

<div *ngIf="orderStatus === status.PENDING_REVIEW" class="chip chip--pending" title={{orderStatus}}>{{ orderStatus }}</div>

<div *ngIf="orderStatus === status.REVIEW_IN_PROGRESS" class="chip chip--review-progress" title={{orderStatus}}>{{ orderStatus }}</div>

<div *ngIf="orderStatus === status.COMPLETED_NEW_VERSION_RECIEVED" class="chip chip--new-revision-received" title={{orderStatus}}>{{ orderStatus }}</div>

<div *ngIf="orderStatus === status.REVISION_REQUESTED" class="chip chip--revision-requested" title={{orderStatus}}>{{ orderStatus }}</div>

<div *ngIf="orderStatus === status.ARCHIVED" class="chip chip--archived" title={{orderStatus}}>{{ orderStatus }}</div>

<div *ngIf="orderStatus === status.PENDING_REVIEWER" class="chip chip--pending-reviewer" title={{orderStatus}}>{{ orderStatus }}</div>

<div *ngIf="orderStatus === status.REVISION_RECEIVED" class="chip chip--new-revision-received" title={{orderStatus}}>{{ orderStatus }}</div>

<div *ngIf="orderStatus === status.CONDITION_SENT_TO_APPRAISER" class="chip chip--sent" title={{orderStatus}}>{{ orderStatus }}</div>

<div *ngIf="orderStatus === status.ON_HOLD" class="chip chip--on-hold" title={{orderStatus}}>{{ orderStatus }}</div>

<div *ngIf="orderStatus === status.PENDING_ESCALATED_REVIEW" class="chip chip--pending-escalated-review" title={{orderStatus}}>{{ orderStatus }}</div>

<div *ngIf="orderStatus === status.ESCALTED_REVIEW_IN_PROGRESS" class="chip chip--escalated-review-inprogress" title={{orderStatus}}>{{ orderStatus }}</div>

<div *ngIf="orderStatus === status.ESCALATED_REVIEW_COMPLETE" class="chip chip--escalated-review-completed" title={{orderStatus}}>{{ orderStatus }}</div>

<div *ngIf="orderStatus === status.CONDITION_SENT_TO_VENDOR" class="chip chip--escalated-review-completed" title={{orderStatus}}>{{ orderStatus }}</div>

<div *ngIf="orderStatus === status.PENDING" class="chip chip--pending" title={{orderStatus}}>{{ orderStatus }}</div>

<div *ngIf="orderStatus === status.IN_PROGRESS" class="chip chip--review-progress" title={{orderStatus}}>{{ orderStatus }}</div>

<div *ngIf="orderStatus === status.READY_FOR_APPRAISER_REVIEW" class="chip chip--ready-for-appraiser-review" title={{orderStatus}}>{{ orderStatus }}</div>



<!-- Conversion Statuses-->

<div *ngIf="orderConversionStatus === conversionStatus.PROCESSING" title={{orderConversionStatus}}><span class="conversion-circle conversion-circle--processing"></span>{{ orderConversionStatus }}</div>
<div *ngIf="orderConversionStatus === conversionStatus.WAITING_FOR_UPLOAD" title={{orderConversionStatus}}><span class="conversion-circle conversion-circle--waitingForUpload"></span>{{ orderConversionStatus }}</div>
<div *ngIf="orderConversionStatus === conversionStatus.SUCCEEDED" title={{orderConversionStatus}}><span class="conversion-circle conversion-circle--succeeded"></span>{{ orderConversionStatus }}</div>
<div *ngIf="orderConversionStatus === conversionStatus.FAILED" title={{orderConversionStatus}}><span class="conversion-circle conversion-circle--failed"></span>{{ orderConversionStatus }}</div>

<!-- Is Manager -->
<div class="status-icon" *ngIf="orderManager === true" title="Yes"><img src="../../../../assets/images/tick.svg" alt="tick" />Yes</div>
<div class="status-icon" *ngIf="orderManager === false" title="No"><app-svg class="not-manager" [svgPath]="'../../../../assets/images/x.svg'"></app-svg>No</div>

<!-- computer vision chip-->
<div *ngIf="textToDisplay" class="cv-chip cv-chip--CV-non-editable" title={{textToDisplay}} [class.cv-chip--CV-non-config-chip]="isGlobalChip">{{ textToDisplay }}</div>
<div *ngIf="cvRemainingWordsCount" class="cv-count-circle cv-count-circle--count">{{ cvRemainingWordsCount }}<span>+</span></div>

<!-- Rules status -->
<div *ngIf="rulesStatus === 'Active'" class="rules-status active-rules">Active</div>
<div *ngIf="rulesStatus === 'Inactive'" class="rules-status inactive-rules">Inactive</div>
