<div mat-dialog-title class="submission__card-header">
  <h3>{{title}} Override</h3>
  <mat-icon fontSet="material-icons-outlined" mat-dialog-close>highlight_off</mat-icon>
</div>

<div mat-dialog-content>
  <div class="submission__card">
    <div class="submission__card-body">
      <div class="submission__input-wrap">
        <div *ngFor="let form of totalForms; let i = index">
          <form [formGroup]="submissionForms[i]">
            <div class="spacer" *ngIf="i !== 0">
              <hr>
            </div>
            <div class="ead-headline-main">
              <p>Select {{ i=== 0 ? 'Request' : 'Additional Request'}} for {{title}} Override</p>
              <app-svg *ngIf="totalForms.length > 1" [svgPath]="'../../../../assets/images/minus-circle.svg'" alt="" (click)="removeForm(i)" title="Remove Request"></app-svg>
            </div>
            <mat-form-field appearance="outline">
              <mat-label>Select Request Code</mat-label>
              <input type="text" matInput [matAutocomplete]="auto" formControlName="type" placeholder="Select Request Code" (click)="filterOptionsType(submissionForms[i].get('type')?.value, i)"
              (ngModelChange)="filterOptionsType(submissionForms[i].get('type')?.value, i)"/>
              <mat-icon matSuffix>keyboard_arrow_down</mat-icon>
              <mat-autocomplete #auto="matAutocomplete">
                <mat-option *ngFor="let option of filteredOptions[i]" [value]="option"
                (click)="filteredCodesFromSelectedType[i] = filterCodesOnSelectedType(i, codesByTypes)">
                  {{ option }}
                </mat-option>
              </mat-autocomplete>
              <mat-hint *ngIf="showTypeOptionError(i)">
                Entered Request Code is not found.
              </mat-hint>
            </mat-form-field>

            <mat-form-field appearance="outline" >
              <mat-label>Select Code Description</mat-label>
              <input type="text" matInput [matAutocomplete]="autoCode" formControlName="code" placeholder="Select Code Description"
              (ngModelChange)="filteredCodesFromSelectedType[i] = filterCodesOnSearch(submissionForms[i].get('code')?.value, i, codesByTypes)"/>
              <mat-icon matSuffix>keyboard_arrow_down</mat-icon>
              <mat-autocomplete #autoCode="matAutocomplete">
                <mat-option *ngFor="let code of filteredCodesFromSelectedType[i]" [value]="code.description"
                (click)="updateCommentRequiredValidation(i)">
                  {{ code.description }}
                </mat-option>
              </mat-autocomplete>
              <mat-hint *ngIf="showCodeError(i)">
                Entered Code Description is not found.
              </mat-hint>
            </mat-form-field>

            <mat-form-field appearance="outline" >
              <mat-label>Add Comment</mat-label>
              <textarea autocomplete="off" matInput rows="4" placeholder="Add comment" formControlName="comment" (focusout)="sharedService.removeSpaces(submissionForms[i].get('comment'))"></textarea>
            </mat-form-field>

          </form>
        </div>
        <div (click)="addForm()" class="link">
            <app-svg [svgPath]="'../../../../assets/images/plus.svg'"></app-svg>
            <div>Add another Request</div>
        </div>
      </div>
    </div>
  </div>
</div>
<div mat-dialog-actions class="submission__actions">
  <button class="submission__btn-outlined" mat-dialog-close>Cancel</button>
  <button class="submission__btn-filled" (click)=" title === 'EAD' ? eadSubmission() : ucdpSubmission()" [disabled]="isSaveDisabled()">Save</button>
</div>
