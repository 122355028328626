<form [formGroup]="addFindingsForm">
<div mat-dialog-content>
    <div class="add-findings__card">
      <div class="add-findings__card-body">
        <div class="add-findings__card-body_input-main">
          <div class="add-findings__decision">
            <mat-form-field appearance="outline" class="add-findings__status-dropdown">
              <mat-label>Select Status</mat-label>
              <mat-select formControlName="status">
                <mat-option value="Approved">Approved</mat-option>
                <mat-option value="Rejected">Rejected</mat-option>
                <mat-option value="Not Applicable">Not Applicable</mat-option>
              </mat-select>
              <mat-icon matSuffix>expand_more</mat-icon>
            </mat-form-field>
          </div>
          <div class="add-findings__input-wrap">
            <mat-form-field appearance="outline" subscriptSizing="dynamic">
              <mat-label>Select Finding Group</mat-label>
              <input matInput [matAutocomplete]="autocomplete" placeholder="Select Finding Group" formControlName="finding_text1"
              (keyup)="filterManualFindingGroupOptions(addFindingsForm.get('finding_text1')?.value,$event)"
                data-test-id="add-finding-group">
              <mat-icon matSuffix>keyboard_arrow_down</mat-icon>
              <mat-autocomplete #autocomplete="matAutocomplete" [displayWith]="displayGroupName.bind(this)" #autoCompleteInput>
                <mat-option class="finding-pre-text" *ngFor="let group of filteredGroups" [value]="group.description">
                  <span>{{ group.mfg_code }}:</span> <span> {{ group.description }}</span>
                </mat-option>
              </mat-autocomplete>
              <mat-hint *ngIf="showGroupNotFoundError()">
                Entered Group is not found.
              </mat-hint>
            </mat-form-field>
          </div>
        </div>
        <div class="add-findings__add">
          <mat-form-field appearance="outline" subscriptSizing="dynamic">
            <mat-label>Add Comment</mat-label>
            <textarea autocomplete="off" matInput required rows="4" placeholder="Add comment" formControlName="comment"  (focusout)="sharedService.removeSpaces(addFindingsForm.get('comment'))"></textarea>
          </mat-form-field>
        </div>
      </div>
    </div>
</div>
<div mat-dialog-actions class="add-findings__actions">
  <button class="add-findings__btn-outlined discard" (click)="closeFindingBox.emit(false)">Discard Changes</button>
  <button class="add-findings__btn-filled" [disabled]="!addFindingsForm.valid" (click)="onSubmit()">Save Changes</button>
</div>
</form>
