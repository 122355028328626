export enum Tabs {
  'all_records' = 0,
  'Active' = 1,
  'Inactive' = 2,
  'Locked' = 3,
}

export enum UserRole {
  ADMIN = 'Admin',
  SYSTEM_ADMIN = 'System Admin',
  REVIEWER = 'Reviewer',
  MANAGER = 'Manager',
  QC_REVIEWER = 'QC Reviewer',
}
