import { Component, Input } from '@angular/core';
import { OrderService } from '../../order.service';

@Component({
  selector: 'app-revision-messages',
  templateUrl: './revision-messages.component.html',
  styleUrls: ['./revision-messages.component.scss']
})
export class RevisionMessagesComponent {
  @Input() orderId: string;
  isTextTruncated: boolean[] = [];
  revisionsData: any[] = [];

  constructor(private orderService: OrderService) {}

  ngOnInit(): void {
    this.getRevisions();
  }

  getRevisions(): void {
    this.orderService.getOrderRevisionHistory(this.orderId)
    .then((response) => {
      this.revisionsData = response.data;
      this.revisionsData.reverse();
      this.isTextTruncated = this.revisionsData.map(() => true);
    })
  }

  readMoreDescription(isTruncated : boolean, index: number) {
    this.isTextTruncated[index] = isTruncated;
  }

}
