<div mat-dialog-title class="form-selection-modal__card-header">
  <h3>{{heading}}</h3>
  <mat-icon fontSet="material-icons-outlined" mat-dialog-close>highlight_off</mat-icon>
</div>
<div mat-dialog-content>
  <div class="form-selection-modal__card">
    <div class="form-selection-modal__card-body">
      <table mat-table [dataSource]="dataSource">
        <caption class="caption-style">Select forms for {{data.type}} submission.</caption>
         <!-- Checkbox Column -->
         <ng-container matColumnDef="select">
          <th mat-header-cell *matHeaderCellDef>
            <mat-checkbox  (change)="$event ? toggleAllRows() : null; onAllCheckboxChange($event)" [checked]="selection.hasValue() && isAllSelected()"
              [indeterminate]="selection.hasValue() && !isAllSelected()" [aria-label]="checkboxLabel()"
              [color]="'#fff'">
            </mat-checkbox>
          </th>
          <td mat-cell *matCellDef="let row">
            <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null; onCheckboxChange($event, row)"
              [checked]="selection.isSelected(row)" [aria-label]="checkboxLabel(row)" [color]="'#fff'">
            </mat-checkbox>
          </td>
        </ng-container>

        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef id="name"> Name </th>
          <td mat-cell *matCellDef="let element"> {{element.name}} </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="selection.toggle(row)"></tr>
      </table>
    </div>
  </div>

  <div class="form-selection-modal__footer">
    <p>Permissions for selected forms</p>
    <div class="form-selection-modal__active-client gap-77">
      <span>Activate client {{data.type}} Auto submission</span>
      <label class="switch">
        <input type="checkbox" [(ngModel)]="submissionToggleState" [disabled]="!isToggleEnabled" (change)="changeAutoSubmissionStatus($event)">
        <span class="slider round"></span>
      </label>
    </div>
    <div class="form-selection-modal__active-client gap-63">
      <span>Activate client {{data.type}} Auto resubmission</span>
      <label class="switch">
        <input type="checkbox" [(ngModel)]="resubmissionToggleState" [disabled]="!isToggleEnabled" (change)="changeAutoResubmissionStatus($event)">
        <span class="slider round"></span>
      </label>
    </div>
  </div>
</div>

<div mat-dialog-actions class="form-selection-modal__actions">
  <button class="form-selection-modal__btn-filled" (click)="autoSubmissionConfigurations()">Done</button>
</div>
