import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { OrderService } from '../../order.service';
import { ToastService } from 'src/app/core/services/toast.service';
import { SharedService } from 'src/app/shared/shared.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { CitiesByState, OverrideRequestPayload } from '../../orders.interface';
import { message } from 'src/app/shared/constants/alerts_messages';

@Component({
  selector: 'app-override-submission',
  templateUrl: './override-submission.component.html',
  styleUrls: ['./override-submission.component.scss']
})
export class OverrideSubmissionComponent {
  title: string;
  submissionForms: FormGroup[] = [];
  totalForms: { form: number}[] = [];
  optionsType: any = [];
  codesByTypes: any;
  filteredOptions: any[] = [];
  filteredCodesFromSelectedType: any[] = [];
  unusedRequestCodes: any[] = [];
  reasonCodeIds: OverrideRequestPayload[] = [];

  constructor(
    public dialogRef: MatDialogRef<OverrideSubmissionComponent>,
    public sharedService: SharedService,
    private orderService: OrderService,
    private fb: FormBuilder,
    private toast: ToastService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.title = data.title;
  }

  ngOnInit() {
    this.addForm();
    this.orderService.getOverRideRequestCodes(this.title).then((response) => {
      const transformedData: { [key: string]: any[] } = {};
      response.data.forEach((item: { [x: string]: any; code: any; }) => {
        const { code, ...reasonCode } = item;

        if (!transformedData[code]) {
          transformedData[code] = [];
        }

        transformedData[code].push(reasonCode);
      });

      this.optionsType = Object.keys(transformedData);
      this.filteredOptions = [this.optionsType];
      this.codesByTypes = transformedData;
     });
  }

  addForm() {
    this.totalForms.push({form: this.totalForms.length })
    const newForm = this.fb.group({
      type: new FormControl('', [Validators.required]),
      code: new FormControl('', [Validators.required]),
      comment: new FormControl('')
    });
    this.submissionForms.push(newForm);
    const unusedRequestCodes = this.submissionForms.map(form => form.get('type')?.value);
    this.unusedRequestCodes = this.optionsType.filter((type: any) => !unusedRequestCodes.includes(type));
    this.filteredOptions.push(this.unusedRequestCodes);
}

  updateCommentRequiredValidation(index: number) {
    const formGroup = this.submissionForms[index] as FormGroup;

    const selectedCodeDescription = formGroup.get('code')?.value;
    const selectedCode = this.filteredCodesFromSelectedType[index]?.find((code: any) =>
      code?.description?.toLowerCase() === selectedCodeDescription.toLowerCase()
    );

    this.reasonCodeIds[index] = {request_code_id : selectedCode?.id};

    const commentControl = formGroup.get('comment');

    if (selectedCode?.is_comment_required) {
      commentControl?.setValidators([Validators.required]);
    } else {
      commentControl?.setValidators(null);
    }

    commentControl?.updateValueAndValidity();
  }

  filterCodesOnSelectedType(index: number, codesByType: CitiesByState) {
    const formGroup = this.submissionForms[index] as FormGroup;
    formGroup.get('code')?.setValue('');
    return codesByType[formGroup.get('type')?.value];
  }

  filterCodesOnSearch(codeValue: string, index: number, codesByType: CitiesByState) {
    const formGroup = this.submissionForms[index] as FormGroup;
    if (codeValue) {
      return codesByType[formGroup.get('type')?.value]?.filter((code: any) =>
        code?.description?.toLowerCase().startsWith(codeValue.toLowerCase())
      );
    }
    return codesByType[formGroup.get('type')?.value];
  }

  filterOptionsType(value: string, index: number) {
    if (value) {
      this.filteredOptions[index] = this.optionsType.filter((option: string) =>
        option.toLowerCase().startsWith(value.toLowerCase())
      );
    } else {
      const unusedRequestCodes = this.submissionForms.map(form => form.get('type')?.value);
      this.unusedRequestCodes = this.optionsType.filter((type: any) => !unusedRequestCodes.includes(type));
      this.filteredOptions[index] = this.unusedRequestCodes;
    }
  }

  showTypeOptionError(index: number): boolean {
    const formGroup = this.submissionForms[index] as FormGroup;
    const selectedOptionType = formGroup.get('type')?.value;

    return (
      selectedOptionType &&
      !this.optionsType?.some((option: string) => option.toLowerCase() === selectedOptionType.toLowerCase())
    );
  }

  showCodeError(index: number): boolean {
    const formGroup = this.submissionForms[index] as FormGroup;
    const selectedCode = formGroup.get('code')?.value;

    return (
      selectedCode &&
      !this.filteredCodesFromSelectedType[index]?.some((code: any) => code.description.toLowerCase() === selectedCode.toLowerCase())
    );
  }

  isSaveDisabled(): boolean {
    return this.submissionForms.some((formGroup: FormGroup) => formGroup.invalid);
  }

  eadSubmission(): void {
    this.submissionForms.forEach((formGroup, index) => {
      const comment = formGroup.get('comment')?.value;
      this.reasonCodeIds[index].comment = comment;
    })
    const payload = { override_requests: this.reasonCodeIds}
    this.orderService.submitToEAD(this.data.orderId, payload)
    .then(() => {
      this.dialogRef.close();
      this.toast.success(message.overrideRequest);
    })
    .catch((error) => {
      this.toast.error(error?.error?.detail);
    })
  }

  ucdpSubmission(): void {
    this.submissionForms.forEach((formGroup, index) => {
      const comment = formGroup.get('comment')?.value;
      this.reasonCodeIds[index].comment = comment;
    })
    const payload = { override_requests: this.reasonCodeIds}
    this.orderService.submitToUCDP(this.data.orderId, payload)
    .then(() => {
      this.dialogRef.close();
      this.toast.success(message.overrideRequest);
    })
    .catch((error) => {
      this.toast.error(error?.error?.detail);
    })
  }

  removeForm(index: number): void {
    const formGroup = this.submissionForms[index] as FormGroup;
    formGroup.reset();
    formGroup.updateValueAndValidity();
    this.submissionForms.splice(index, 1);
    this.totalForms.splice(this.totalForms.length - 1, 1);
    this.reasonCodeIds.splice(index, 1);
    this.filteredOptions.splice(index, 1);
    this.filteredCodesFromSelectedType.splice(index, 1);
    this.unusedRequestCodes = this.optionsType.filter((type: any) =>
      !this.submissionForms.map(form => form.get('type')?.value).includes(type)
    );
  }

}
