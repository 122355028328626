import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-findings-progress-bar',
  templateUrl: './findings-progress-bar.component.html',
  styleUrls: ['./findings-progress-bar.component.scss']
})
export class FindingsProgressBarComponent {
  @Input() progress = 0;
}
