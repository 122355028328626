<div class="chat">
  <div class="chat__messages-wrapper">

    <!-- show when there's no msg -->
   <div class="chat__empty" *ngIf="messagesData.length === 0 && !showChatParticipants">
      <div class="chat__empty-inner">
        <span class="chat__empty-shape"></span>
        <h6 class="chat__empty-heading">No Messages</h6>
      </div>
      <p class="chat__empty-text">{{displayMessage}}</p>
    </div>


      <div class="chat__messages" *ngIf="messagesData.length > 0 && !showChatParticipants">
        <div class="chat__messages-list">
          <div *ngFor="let msg of messagesData; let i = index">

            <div [ngClass]="msg.sender.external_id === currentUserId ? 'chat__message-item' : 'chat__message-item chat__message-item--incoming'">
              <img class="message-avatar" [src]="msg.sender.public_image ? msg.sender.public_image : msg.sender.avatar_link" alt="">
              <div class="msg-content">
                <div class="head">
                    <div class="head-inner">
                      <h6 class="name">{{msg.sender.first_name}} {{msg.sender.last_name}}</h6>
                      <p class="role">{{msg.sender.role}}</p>
                    </div>
                    <p class="msg-time"><span *ngIf="msg.send_via_email">Sent on Email |</span> {{msg.created_on | localDate:'hh:mm a' }} | {{msg.created_on | localDate:'MM/dd/yy' }}
                      <ng-container *ngIf="userConfig.checkPermissions(permission.DELETE_MESSAGE)">
                        <mat-icon [matMenuTriggerFor]="actionMenu">more_vert</mat-icon>
                        <mat-menu #actionMenu="matMenu" class="action-menu">
                          <button [disabled]="isPreview"
                            matTooltip="Delete" aria-label="Delete Message" class="msg-action-delete"
                            (click)="openDeleteMessageConfirmationModal(msg.id)">
                            <app-svg [svgPath]="'../../../../assets/images/trash-2.svg'"></app-svg>
                            <span>Delete</span>
                          </button>
                        </mat-menu>
                    </ng-container>
                    </p>
                </div>
                <p class="msg-text"
                  [innerHTML]="highlightSearchTerm(msg.content, searchTerm)">
                </p>
              </div>
            </div>

          </div>
          <div #target></div>
        </div>

      </div>
      <button class="btn-primary" (click)="toggleMessageBox()" *ngIf="!showMessageBox && !showChatParticipants" [disabled]="unAssigned">
        <app-svg [svgPath]="'../../../../assets/images/pencil.svg'"></app-svg>
        Message
      </button>
    <!-- msg box -->
    <form [formGroup]="sendMessageForm" *ngIf="showMessageBox && !showChatParticipants">
      <div class="msg-box">
        <div class="msg-box__head">
          <div>
            <mat-form-field appearance="outline">
              <mat-select panelClass="msg-recipient-dropdown" placeholder="Select Recipient" data-test-id="msg-select" formControlName="recipient" [(value)]="selectedRecipient"
                [title]="sendMessageForm.get('recipient')?.value ? selectedRecipient.first_name + ' ' + selectedRecipient.last_name + ' | ' + selectedRecipient.role : 'Select Recipient'">
                <mat-option *ngFor="let recipient of recipientList" [value]="recipient">{{recipient.role === 'Client' ? recipient.company_name : recipient.first_name }} {{recipient.role === 'Client' ? '' :recipient.last_name }} | {{recipient.role}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div>
            <mat-checkbox class="msg-box__checkbox" [color]="'warn'" data-test-id="msg-checkbox" formControlName="sendViaEmail">Send as email</mat-checkbox>
          </div>
          <div>
            <app-svg (click)="toggleMessageBox()" [svgPath]="'../../../../assets/images/close-2.svg'"></app-svg>
          </div>
        </div>
        <div class="msg-box__body">
          <textarea rows="4" data-test-id="msg-input" formControlName="comment" (focusout)="removeSpaces(sendMessageForm.get('comment'))"></textarea>
          <button data-test-id="msg-send-btn" [disabled]="!sendMessageForm.valid || isPreview || unAssigned" (click)="sendMessage()"><img src="../../../../assets/images/send.svg" alt=""></button>
        </div>
      </div>
    </form>

  </div>
  <div class="chat__participants" *ngIf="!showChatParticipants">
    <div class="chat__participants-inner">
      <div class="chat__list">
        <div class="chat__list-item" *ngIf="reviewer && reviewer?.assigned_to_reviewer">
          <img class="participant-avatar" [src]="reviewer?.assigned_to_reviewer?.user?.public_image ? reviewer?.assigned_to_reviewer?.user?.public_image : reviewer?.assigned_to_reviewer?.user?.avatar_link" alt="">
        </div>
        <div class="chat__list-item" *ngIf="clientName">
          <img class="participant-avatar" [src]="clientImage" alt="">
        </div>
        <div class="chat__list-item" *ngIf="reviewer && reviewer?.assigned_to_vendor">
          <img class="participant-avatar" [src]="reviewer?.assigned_to_vendor?.user?.public_image ? reviewer?.assigned_to_vendor?.user?.public_image : reviewer?.assigned_to_vendor?.user?.avatar_link" alt="">
        </div>
        <div class="chat__list-item toggle-icon" (click)="toggleChatParticipantsIcons()" data-test-id="msg-collapse">
          <mat-icon>keyboard_tab</mat-icon>
        </div>
      </div>
    </div>
  </div>

  <div class="chat__participants view" *ngIf="showChatParticipants" [@slideInOut]>
    <div class="back-btn" (click)="toggleChatParticipantsIcons()">
      <app-svg [svgPath]="'../../../../assets/images/arrow-left.svg'"></app-svg>
      <span>Back to Messages</span>
    </div>
    <div class="chat__list" [ngClass]="{activeParticipants: showChatParticipants}">
      <div class="chat__list-item" *ngIf="reviewer && reviewer?.assigned_to_reviewer">
        <div class="participant-wrapper">
          <img class="participant-avatar" [src]="reviewer?.assigned_to_reviewer?.user?.public_image ? reviewer?.assigned_to_reviewer?.user?.public_image : reviewer?.assigned_to_reviewer?.user?.avatar_link" alt="">
          <h6 class="chat__participant-name">{{reviewer?.assigned_to_reviewer?.user?.last_name ? reviewer?.assigned_to_reviewer?.user?.first_name + ' ' + reviewer?.assigned_to_reviewer?.user?.last_name : reviewer?.assigned_to_reviewer?.user?.last_name }}</h6>
          <p class="chat__participant-role">{{reviewer?.assigned_to_reviewer?.user?.is_manager ? 'Manager' : 'Reviewer' }}</p>
        </div>
        <div (click)="participantClickToSendMsg(reviewer?.assigned_to_reviewer?.user)">
          <app-svg [svgPath]="'../../../../assets/images/mail.svg'"></app-svg>
        </div>
      </div>
      <div class="chat__list-item" *ngIf="clientName">
        <div class="participant-wrapper">
          <img class="participant-avatar" [src]="clientImage" alt="">
          <h6 class="chat__participant-name">{{clientName}}</h6>
          <p class="chat__participant-role">Client</p>
        </div>
      </div>
      <div class="chat__list-item" *ngIf="reviewer && reviewer?.assigned_to_vendor">
        <div class="participant-wrapper">
          <img class="participant-avatar" [src]="reviewer?.assigned_to_vendor?.user?.public_image ? reviewer?.assigned_to_vendor?.user?.public_image : reviewer?.assigned_to_vendor?.user?.avatar_link" alt="">
          <h6 class="chat__participant-name">{{reviewer?.assigned_to_vendor?.user?.last_name ? reviewer?.assigned_to_vendor?.user?.first_name  + ' ' + reviewer?.assigned_to_vendor?.user?.last_name : reviewer?.assigned_to_vendor?.user?.last_name }}</h6>
          <p class="chat__participant-role">Appraiser</p>
        </div>
        <div (click)="participantClickToSendMsg(reviewer?.assigned_to_vendor?.user)">
          <app-svg [svgPath]="'../../../../assets/images/mail.svg'"></app-svg>
        </div>
      </div>
    </div>
  </div>


</div>
