import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActionPermissions } from 'src/app/shared/constants';
import { UserConfigurationService } from 'src/app/core/services/user-configuration.service';
import { ToastService } from 'src/app/core/services/toast.service';
import { OrderService } from '../../order.service';
import { SendEmailComponent } from '../send-email/send-email.component';
import { ActivatedRoute } from '@angular/router';
import { OrderCompletedModalComponent } from '../order-completed-modal/order-completed-modal.component';
import { OrderStatuses } from 'src/app/shared/enums/orders';
import { message } from 'src/app/shared/constants/alerts_messages';

@Component({
  selector: 'app-findings-stats-view',
  templateUrl: './findings-stats-view.component.html',
  styleUrls: ['./findings-stats-view.component.scss']
})
export class FindingsStatsViewComponent {
  @Input() totalfindings = 0;
  @Input() resolvedfindings = 0;
  @Input() unresolvedfindings = 0;
  @Input() orderId: string;
  @Input() orderNo: string;
  @Input() isPreview: boolean;
  @Input() orderStatus: string;
  @Input() rejectedFindings = 0;
  @Input() vendorAndReviewer: any;
  @Input() countData = {
    'Approved': 0,
    'Rejected': 0,
    'Not Applicable': 0
  };

  @Output() findingsType = new EventEmitter<string>();
  @Output() backToReport = new EventEmitter<boolean>();
  @Output() updateOrderStatus: EventEmitter<{ reloadDoc: boolean, isActionPerformed: boolean }> = new EventEmitter<{ reloadDoc: boolean, isActionPerformed: boolean }>();

  permission = ActionPermissions;
  token: string;
  showDetails = false;
  @Input() activeFilter = 'unResolved';

  constructor(
    public dialog: MatDialog,
    public userConfig: UserConfigurationService,
    private orderService: OrderService,
    private toastr: ToastService,
    private route: ActivatedRoute
  ) {
    this.route.params.subscribe(params => {
      this.orderId = params['orderId'];
      this.token = params['token'];
    });
  }

  calculateProgress(): number {
    return this.totalfindings === 0 ? 0 :
      Math.floor((this.resolvedfindings / this.totalfindings) * 100);
  }

  downloadPDF() {
    if (this.orderId) {
      this.orderService.downloadPDF(this.orderId).then(
        (response) => {
          const filename = `Order-${this.orderNo}`;
          const dataType = response.type;
          const binaryData = [];
          binaryData.push(response);
          const downloadLink = document.createElement('a');
          downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, { type: dataType }));
          if (filename)
            downloadLink.setAttribute('download', filename);
          document.body.appendChild(downloadLink);
          downloadLink.click();
          this.toastr.success(response.message);
        },
        (err) => {
          this.toastr.error(err.message);
        },
      );
    }
  }

  openSendEmailModal() {
    this.dialog.open(SendEmailComponent, {
      panelClass: ['create-review-modal'],
      maxWidth: '100%',
      disableClose: true,
      autoFocus: false,
      data: this.orderId
    });
  }

  filterFindings(findingType: string): void {
    this.activeFilter = findingType;
    this.findingsType.emit(findingType);
  }

  openOrderCompletionModal(heading: string, contentData: string, actionHeading?: string) {
    const dialogRef = this.dialog.open(OrderCompletedModalComponent, {
      panelClass: ['order-complete-modal'],
      maxWidth: '100%',
      autoFocus: false,
      disableClose: true,
      data: {
        heading: heading,
        contentData: contentData,
        orderId: this.orderId,
        actionHeading: actionHeading
      }
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.orderService.updateOrders(this.orderId, 'REVISION_REQUESTED', '').subscribe((response) => {
          if (response) {
            this.updateOrderStatus.emit({ reloadDoc: false, isActionPerformed: true });
          }
        },
        (error) => {
          this.toastr.error(error.error.detail);
        });
      }
    });
  }

  markAsComplete(): void {
    if (this.rejectedFindings > 0) {
      return;
    }
    if (this.orderStatus !== OrderStatuses.COMPLETED) {
      this.orderService.updateOrders(this.orderId, 'COMPLETED', '').subscribe((response) => {
        if (response) {
          this.updateOrderStatus.emit({ reloadDoc: false, isActionPerformed: true });
          this.openOrderCompletionModal('Order Completed', message.orderCompletionConfirmation, 'Preview')
        }
      },
        (error) => {
          this.toastr.error(error.error.detail);
        });
    } else {
      this.toastr.info(message.alreadymarkedAsCompletedReview);
    }
  }

  requestForRevision(): void {
    if (this.rejectedFindings === 0) {
      return;
    }
    if (this.orderStatus !== OrderStatuses.REVISION_REQUESTED) {
      this.openOrderCompletionModal('Request for Revision', 'Are you sure you want to perform this action?', 'Done');
    } else {
      this.toastr.info(message.alreadyRequestedRevision);
    }
  }

  toggleSummary(): void {
    this.showDetails = !this.showDetails;
  }
}

