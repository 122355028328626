import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { config } from './constants/config';
import { Router } from '@angular/router';
import { OrderStatuses } from '../shared/enums/orders';
import { NewNotificationsCount, NotificationResponse } from './interfaces/notifications.interface';
import { UserConfigurationService } from '../core/services/user-configuration.service';

@Injectable({
  providedIn: 'root'
})
export class SharedService {

  constructor(private router: Router, private http: HttpClient, private userConfig: UserConfigurationService) { }

  getOrderMessages(orderId: string, searchTerm: string, token?: string): Observable<any> {
    const params = new HttpParams().set('search', searchTerm);
    const options = { params };
    return this.http.get<any>(`messages/${orderId}${token ? `/${config.magicLink.token}${token}` : ''}`, options);
  }

  sendOrderMessages(data: any, token?: string): Observable<any> {
    return this.http.post<any>(`messages/${token ? `/${config.magicLink.token}${token}` : ''}`, data);
  }

  deleteOrderMessages(messageId: string): Observable<any> {
    return this.http.delete<any>(`messages/${messageId}`);
  }

  updateMessageStatus(messageId: string, messageStatusId: any, token?: string): Observable<any> {
    return this.http.patch<any>(`messages/${messageId}${token ? `/${config.magicLink.token}${token}` : ''}`, messageStatusId);
  }

  getMessagePlatform(token?: string): Observable<any> {
    return this.http.get<any>(`messages/message-platform${token ? `/${config.magicLink.token}${token}` : ''}`);
  }

  getMessageStatus(token?: string): Observable<any> {
    return this.http.get<any>(`messages/message-status${token ? `/${config.magicLink.token}${token}` : ''}`);
  }

  getMessageRecipients(messageId: string, token?: string): Observable<any> {
    return this.http.get<any>(`messages/message-recipients/${messageId}${token ? `/${config.magicLink.token}${token}` : ''}`);
  }

  navigateToMessages(orderId: string, orderStatus?: string, orderReview?: any): void {
    const currentUser = this.userConfig.getUserConfigurationsFromLS();
    const isCurrentUser = orderReview.assigned_to_reviewer
    && orderReview.assigned_to_reviewer?.user?.id != currentUser.user.id;
    if (orderStatus === OrderStatuses.COMPLETED || orderStatus === OrderStatuses.ARCHIVED || orderStatus === OrderStatuses.REVISION_REQUESTED || isCurrentUser) {
      this.router.navigateByUrl(`orders/order-review/${orderId}?tab=2&reviewId=${orderReview.id}&isPreview=true`);
    } else {
      this.router.navigateByUrl(`orders/order-review/${orderId}?tab=2&reviewId=${orderReview.id}`);
    }
  }

  navigateToReview(orderId: string, orderStatus?: string, orderReview?: any) {
    const currentUser = this.userConfig.getUserConfigurationsFromLS();
    const isCurrentUser = orderReview.assigned_to_reviewer
    && orderReview.assigned_to_reviewer?.user?.id != currentUser.user.id;
    if (orderStatus === OrderStatuses.COMPLETED || orderStatus === OrderStatuses.ARCHIVED || orderStatus === OrderStatuses.REVISION_REQUESTED || isCurrentUser) {
      this.router.navigateByUrl(`orders/order-review/${orderId}?reviewId=${orderReview.id}&isPreview=true`);
    } else {
      this.router.navigateByUrl(`orders/order-review/${orderId}?reviewId=${orderReview.id}`);
    }
  }

  getNotificationsList(data: any): Observable<NotificationResponse> {
    const params = new HttpParams({ fromObject: data });
    const options = { params };
    return this.http.get<NotificationResponse>(`notifications/`, options);
  }

  getNewNotificationsCount(): Observable<NewNotificationsCount> {
    return this.http.get<NewNotificationsCount>(`notifications/new-notification-counts`);
  }

  fetchAllRulesForRuleEngine(slugUrl: string, data: any): Observable<any> {
    const params = new HttpParams({ fromObject: data });
    const options = { params };
    return this.http.get<any>(`${slugUrl}`, options);
  }

  ruleEngineUpdateStatues(slugUrl: string, data:any, ruleId: string): Observable<any> {
    return this.http.put<any>(slugUrl === 'mfg/' ? `${slugUrl}?mfg_id=${ruleId}` : `${slugUrl}?rule_id=${ruleId}`, data);
  }

  updateNotificationStatus(): Observable<any> {
    return this.http.get<any>(`notifications/is-new-status/`);
  }

  removeSpaces(control: any): void {
    const newValue = control.value.trim();
    control.setValue(newValue);
  }

  deleteManualFinding(findingId: string): Observable<any> {
    return this.http.delete<any>(`review_findings/${findingId}`);
  }

  deleteManualFindingGroup(MfgId: string): Observable<any> {
    return this.http.delete<any>(`mfg/?mfg_id=${MfgId}`);
  }

  getFormsName(): Promise<any> {
    return this.http.get<any>(`forms/`).toPromise();
  }

  downloadClientImage(id: string): Promise<any> {
    return this.http.get(`clients/download/client?client_id=${id}`).toPromise();
  }

  downloadProfileImage(name: string): Observable<any> {
    return this.http.get(`documents/download?filename=${name}`);
  }

  getOrderFlags(data: any): Observable<any> {
    const params = new HttpParams({ fromObject: data });
    const options = { params };
    return this.http.get(`orders/order-flags/`, options);
  }

  getOrderProducts(data: any): Observable<any> {
    const params = new HttpParams({ fromObject: data });
    const options = { params };
    return this.http.get(`orders/products/`, options);
  }

  getOrdersStatus(data: any): Observable<any> {
    const params = new HttpParams({ fromObject: data });
    const options = { params };
    return this.http.get(`order_statues/order-statuses`, options);
  }

}
