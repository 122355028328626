import { Component, Inject } from '@angular/core';
import { OrderService } from '../../order.service';
import { ToastService } from 'src/app/core/services/toast.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatChipInputEvent } from '@angular/material/chips';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { config } from 'src/app/shared/constants/config';
import { message } from 'src/app/shared/constants/alerts_messages';

@Component({
  selector: 'app-send-email',
  templateUrl: './send-email.component.html',
  styleUrls: ['./send-email.component.scss'],
})
export class SendEmailComponent {
  emails: string[] = [];
  addOnBlur = true;
  filters = {
    order_id: ''
  };
  readonly separatorKeysCodes = [ENTER, COMMA] as const;

  constructor(public orderService: OrderService,
    private toastr: ToastService,
    @Inject(MAT_DIALOG_DATA) private data: string,
    private dialogRef: MatDialogRef<any>) {
    this.filters.order_id = data;
  }

  add(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();
    if (value) {
      if (this.validateEmail(value)) {
        this.emails.push(value);
      }
    }
    event.chipInput!.clear();
  }

  private validateEmail(email: string) {
    const re = config.emailRegex;
    return re.test(String(email).toLowerCase());
  }

  remove(email: string): void {
    const index = this.emails.indexOf(email);

    if (index >= 0) {
      this.emails.splice(index, 1);
    }
  }

  onSendEmail() {
    this.orderService.sendEmail(this.filters, this.emails).then(
      (response: any) => {
        this.toastr.success(message.emailSent);
        this.dialogRef.close();
      },
      (err) => {
        this.toastr.error(err.message);
      },
    );
  }
}
