import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { Router } from '@angular/router';
import { map } from 'rxjs';

@Injectable()
export class AuthGuard {
  constructor(private afAuth: AngularFireAuth, private router: Router) { }

  canActivate() {
    return this.afAuth.authState.pipe(
      map((user) => {
        if (user) {
          // User is authenticated, allow access to the route
          return true;
        } else if (window.location.href.includes('appraiser-link')) {
          return true;
        } else {
          // User is not authenticated, redirect to the login page or any other non-authenticated page
          this.router.navigate(['auth/login']);
          return false;
        }
      })
    );
  }
}
