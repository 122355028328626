<div class="error-container">
    <div class="error-div">
        <p class="error-bg">404</p>
        <p class="error-msg">OOOPS! <br>Page cannot be found.</p>
    </div>
    <div class="logo-img">
        <img src="../../../assets/images/vonniqc-logo.svg" alt="" class="">
    </div>
   <a class="link" href="/">Back to homepage</a>
   <a class="link" (click)="logoutUser()">Logout</a>
</div>
