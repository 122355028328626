import { Component, Input } from '@angular/core';
import { OrderConversionStatuses, OrderStatuses } from 'src/app/shared/enums/orders';

@Component({
  selector: 'app-order-status',
  templateUrl: './order-status.component.html',
  styleUrls: ['./order-status.component.scss']
})
export class OrderStatusComponent {
  @Input() orderStatus: string;
  @Input() orderConversionStatus: string;

  @Input() orderManager: boolean;
  @Input() rulesStatus: string;
  @Input() textToDisplay: string;
  @Input() cvRemainingWordsCount: number;
  @Input() isGlobalChip= false

  status = OrderStatuses;
  conversionStatus = OrderConversionStatuses;

}
