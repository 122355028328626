import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable()
export class RulesService {
  constructor(private http: HttpClient) {}

  getOperatorsAndFields(data: any): Promise<any> {
    const params = new HttpParams({ fromObject: data });
    const options = { params };
    return this.http.get(`nan-std-rules/operators-fields`, options).toPromise();
  }

  createNANStdRule(data: any): Promise<any> {
    return this.http.post('nan-std-rules/', data).toPromise()
  }

  updateNANStdRule(data: any): Promise<any> {
    return this.http.post('nan-std-rules/', data).toPromise()
  }

  createRestrictedWord(data: any): Promise<any> {
    return this.http.post('restricted_words/', data).toPromise()
  }

  createQuestion(data: any, clientId:string): Promise<any> {
    return this.http.post(`clients/questions/${clientId}`, data).toPromise()
  }

  updateQuestion(data: any, clientId:string, rule_id:string): Promise<any> {
    return this.http.put(`clients/questions/${clientId}?rule_id=${rule_id}`, data).toPromise()
  }

  getCategories() {
    return this.http.get(`restricted_words/category`).toPromise();
  }

  createManualFindingGroup(data: any): Promise<any> {
    return this.http.post('mfg/', data).toPromise()
  }

  createGaarRule(data: any, clientId:string): Promise<any> {
    return this.http.post(`gaar-rules/`, data).toPromise()
  }

  updateGaarRule(data: any, clientId:string, rule_id:string): Promise<any> {
    return this.http.put(`gaar-rules/?rule_id=${rule_id}`, data).toPromise()
  }

  mapNANRuleWithGaar(data: any): Promise<any> {
    return this.http.post('nan-std-rules/create-association', data).toPromise()
  }

  uploadMismo(code:string, data: any): Promise<any> {
    return this.http.post(`nan-std-rules/upload-mismo?rule_code=${code}`, data).toPromise()
  }

  checkNSconditionsStatus(code:string, data: any): Promise<any> {
    return this.http.post(`nan-std-rules/execute-conditions?rule_code=${code}`, data).toPromise()
  }

  getNSRuleById(ruleId: string): Promise<any> {
    return this.http.get(`nan-std-rules/${ruleId}`).toPromise()
  }

  deassociateRule(data: any): Promise<any> {
    return this.http.put(`nan-std-rules/delete-association`, data).toPromise()
  }

  exportAssociationsAsCSV(data: any): Promise<any> {
    const params = new HttpParams({ fromObject: data });
    const options: {
      headers?: HttpHeaders;
      observe?: 'body';
      params?: HttpParams;
      reportProgress?: boolean;
      responseType: 'blob';
      withCredentials?: boolean;
    } = {
      responseType: 'blob',
      params: params
    };
    return this.http.post(`nan-std-rules/download-association-csv`, [], options).toPromise();
  }

}
