<div class="tab-content">
  <h3 class="rules__heading">{{ ruleName }}</h3>

  <div class="rules__head-wrap">
    <div class="rules__single-input-wrap" *ngIf="clientChecklist">
      <label>Checklist Type</label>
      <mat-form-field class="rules__select">
        <mat-select [value]="0" (selectionChange)="getRulesOnChange($event.value)">
          <mat-option [value]="0">{{ ruleEngine.ACI_RULES }}</mat-option>
          <mat-option [value]="1">{{ ruleEngine.NAN_STANDARD_RULES }}</mat-option>
          <mat-option [value]="2">{{ ruleEngine.RESTRICTED_WORDS }}</mat-option>
          <mat-option [value]="3">{{ ruleEngine.QUESTIONS }}</mat-option>
          <mat-option [value]="4">{{ ruleEngine.GAAR }}</mat-option>
        </mat-select>
        <mat-icon matSuffix>expand_more</mat-icon>
      </mat-form-field>
    </div>

    <div class="rules__single-input-wrap">
      <label>Search</label>
      <div class="rules__input-box">
        <input
          type="text"
          class="rules__input"
          [placeholder]="
            selectedRule === 3 && clientId ? 'Search Question' : 'Search code or description'
          "
          [(ngModel)]="searchTerm"
          (keyup.enter)="searchRule()"
        />
        <div class="close-icon" *ngIf="searchTerm" (click)="resetSearchFilter()">
          <app-svg [svgPath]="'../../../../assets/images/x-circle.svg'"></app-svg>
        </div>
        <mat-icon
          fontSet="material-icons-outlined"
          (click)="searchRule()"
          data-test-id="client-profile-listing-search-btn"
          >search</mat-icon
        >
      </div>
    </div>

    <div class="rules__single-input-wrap">
      <label>Status</label>

      <mat-form-field class="rules__select">
        <mat-select [value]="'all'" (selectionChange)="getStatusOnChange($event.value)">
          <mat-option value="all">All ({{ statusCount?.all_records || 0 }})</mat-option>
          <mat-option value="active">Active ({{ statusCount?.active || 0 }})</mat-option>
          <mat-option value="inactive">Inactive ({{ statusCount?.inactive || 0 }})</mat-option>
        </mat-select>
        <mat-icon matSuffix>expand_more</mat-icon>
      </mat-form-field>
    </div>

    <button
      *ngIf="
        (!clientId &&
          (ruleName == ruleEngine.NAN_STANDARD_RULES || ruleName == ruleEngine.RESTRICTED_WORDS || ruleName == ruleEngine.GAAR)) ||
        ruleName == ruleEngine.QUESTIONS ||
        ruleName == ruleEngine.MANUAL_FINDING_GEOUPS
      "
      class="rules__btn-filled"
      [matMenuTriggerFor]="ruleName === ruleEngine.NAN_STANDARD_RULES ? actionMenu : null"
      (click)="ruleName !== ruleEngine.NAN_STANDARD_RULES ? addRuleModal(ruleName) : null"
      data-test-id="rules-create-btn"
    >
      <mat-icon>add</mat-icon>Add
      {{
        ruleName == ruleEngine.NAN_STANDARD_RULES
          ? 'Rule'
          : ruleName == ruleEngine.QUESTIONS
          ? 'Questionnaire'
          : ruleName == ruleEngine.MANUAL_FINDING_GEOUPS
          ? 'Group'
          : ruleName == ruleEngine.GAAR ? 'Gaar Rule' : 'Word'
      }}
    </button>
    <mat-menu color="primary" #actionMenu="matMenu">
      <button mat-menu-item (click)="navigateToBasicExpressionBuilder()">Basic Builder</button>
      <button mat-menu-item (click)="addRuleModal(ruleName)">Advance Builder</button>
    </mat-menu>
  </div>

  <div class="rules__table-wrap">
    <app-global-mat-table
      [dataSource]="dataSource"
      [columns]="columnConfiguration"
      (actionPerformedOnTable)="actionPerformedOnTable($event)"
      [pageInfo]="pageInfo"
      [totalRecords]="totalRecords"
      [listType]="listType"
    ></app-global-mat-table>
  </div>
</div>
