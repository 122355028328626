import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EadPayload, UcdpPayload } from '../shared/interfaces/clients';

@Injectable({
  providedIn: 'root',
})
export class ClientProfileService {
  constructor(private http: HttpClient) { }

  addClient(data: any): Promise<any> {
    return this.http.post('clients/', data).toPromise();
  }

  getClientListing(data: any): Promise<any> {
    const params = new HttpParams({ fromObject: data });
    const options = { params };
    return this.http.get(`clients/all`, options).toPromise();
  }

  UpdateClient(id: string, status: any): Promise<any> {
    return this.http.put(`clients/update/${id}`, status).toPromise();
  }

  getClient(id: string): Promise<any> {
    return this.http.get(`clients/${id}`).toPromise();
  }

  deleteQuestion(clientId: string, questionId: string): Promise<any> {
    return this.http.delete(`clients/questions/${clientId}?rule_id=${questionId}`).toPromise();
  }

  addEADconfigurations(data: EadPayload): Promise<any> {
    return  this.http.post(`ead_configuration/`, data).toPromise();
  }

  updateEADconfigurations(clientId: string, data: EadPayload): Promise<any> {
    return  this.http.put(`ead_configuration/${clientId}`, data).toPromise();
  }

  addUCDPconfigurations(data: UcdpPayload): Promise<any> {
    return  this.http.post(`ucdp_configuration/`, data).toPromise();
  }

  updateUCDPconfigurations(clientId: string, data: UcdpPayload): Promise<any> {
    return  this.http.put(`ucdp_configuration/${clientId}`, data).toPromise();
  }

}
