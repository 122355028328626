import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ActionPermissions } from 'src/app/shared/constants';
import { UserConfigurationService } from 'src/app/core/services/user-configuration.service';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { OrderReviewerAssignmentComponent } from '../order-reviewer-assignment/order-reviewer-assignment.component';
import { OrderService } from '../../order.service';
import { AuthService } from 'src/app/auth/auth.service';
import { ToastService } from 'src/app/core/services/toast.service';
import { AppraisalNumbers, ModalTitle, OrderStatuses } from 'src/app/shared/enums/orders';
import { message } from 'src/app/shared/constants/alerts_messages';
import { OrderCompletedModalComponent } from '../order-completed-modal/order-completed-modal.component';
import { SafeHtml } from '@angular/platform-browser';
import { OverrideSubmissionComponent } from '../override-submission/override-submission.component';
import { LayoutPreferenceComponent } from '../layout-preference/layout-preference.component';
import { DeleteAppraisalComponent } from '../delete-appraisal/delete-appraisal.component';

@Component({
  selector: 'app-order-review-header',
  templateUrl: './order-review-header.component.html',
  styleUrls: ['./order-review-header.component.scss']
})
export class OrderReviewHeaderComponent {
  @Output() orderHeaderUpdated: EventEmitter<{ reloadDoc: boolean, isActionPerformed: boolean }> = new EventEmitter<{ reloadDoc: boolean, isActionPerformed: boolean }>();
  @Output() toggleStateChange = new EventEmitter<boolean>();
  @Output() orderInfoCollapse = new EventEmitter<boolean>();
  @Output() updateLayoutPreference = new EventEmitter<boolean>();
  @Output() backToReport = new EventEmitter<boolean>();

  permission = ActionPermissions;
  orderStatuses = OrderStatuses;
  ModalTitle = ModalTitle;
  appraisalNumber = AppraisalNumbers;

  @Input() formHtml: SafeHtml;
  @Input() toggleState: boolean;
  @Input() page: string;
  @Input() orderNo: string;
  @Input() source: string;
  @Input() orderStatus: string;
  @Input() revisionNumber: number;
  @Input() reviewId: string;
  @Input() resolvedFindings = 0;
  @Input() totalFindings = 0;
  @Input() rejectedfindings = 0;
  @Input() isPreview: boolean;
  @Input() isEADactive: boolean;
  @Input() isUCDPactive: boolean;
  @Input() isRightSidePreference = false;
  @Input() currentUserId: string;
  @Input() eadAppraisalNumber: string;
  @Input() ucdpAppraisalNumber: string;
  @Input() revisionRequested = false;
  @Input() isEADsubmitted: boolean;
  @Input() isUCDPsubmitted: boolean;
  @Input() vendorAndReviewer: any;

  orderId: string;
  token: string;
  toastMsgs = message;

  constructor(
    public userConfig: UserConfigurationService,
    public dialog: MatDialog,
    public router: Router,
    private route: ActivatedRoute,
    private orderService: OrderService,
    private authService: AuthService,
    private toastr: ToastService
  ) {
  }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.orderId = params['orderId'];
      this.token = params['token'];
    });
  }

  openReviwerAssignmentDialog(title: string) {
    const dialogRef = this.dialog.open(OrderReviewerAssignmentComponent, {
      panelClass: ['documents-modal'],
      maxWidth: '100%',
      autoFocus: false,
      disableClose: true,
      data: {
        orderNumber: this.orderNo,
        title
      },
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.orderHeaderUpdated.emit({ reloadDoc: false, isActionPerformed: true });
      }
    });
  }

  assignToMyself(): void {
    this.authService.getCurrentUser().then((user) => {
      const requestBody: any = []
      this.orderService.updateReviewer(requestBody).subscribe((response) => {
        if (response) {
          this.orderHeaderUpdated.emit({ reloadDoc: false, isActionPerformed: true });
          response?.detail ? this.toastr.error(response?.detail) :
            this.toastr.success(message.assignToMyself);
        }
      },
        (error) => {
          this.toastr.error(error.error.detail);
        });
    });
  }

  confirmActionRequestForRevision(status: string, param: string, isCompleted: boolean, toastMsg: string) {
    const dialogRef = this.dialog.open(OrderCompletedModalComponent, {
      panelClass: ['order-complete-modal'],
      maxWidth: '100%',
      autoFocus: false,
      disableClose: true,
      data: {
        heading: 'Request for Revision',
        contentData: "Are you sure you want to perform this action?",
        orderId: this.orderId,
        reviewId: this.reviewId,
        actionHeading: 'Save'
      }
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
      this.updateOrderReviewStatus(status, param, isCompleted, toastMsg)
      }
    });
  }

  updateOrderReviewStatus(status: string, param: string, isCompleted: boolean, toastMsg: string) {
    if (this.orderStatus === status) {
      this.toastr.info(`${message.sameStatusError} ${status} state.`);
      return
    }
    this.orderService.updateOrders(this.orderId, param, '').subscribe((response) => {
      if (response) {
        if (isCompleted) {
          this.router.navigateByUrl(`orders`);
        }
        this.orderHeaderUpdated.emit({ reloadDoc: false, isActionPerformed: true });
        this.toastr.info(toastMsg);
      }
    },
      (error) => {
        this.toastr.error(error.error.detail);
      });
  }

  archiveOrderDialog() {
    if (this.orderStatus !== OrderStatuses.ARCHIVED) {
      const dialogRef = this.dialog.open(OrderCompletedModalComponent, {
        panelClass: ['order-complete-modal'],
        maxWidth: '100%',
        autoFocus: false,
        disableClose: true,
        data: {
          heading: 'Archive Order',
          contentData: message.archiveOrderConfirmation,
          orderId: this.orderId,
          orderStatus: this.orderStatus,
          actionHeading: 'Done'
        },
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.orderHeaderUpdated.emit({ reloadDoc: false, isActionPerformed: true });
        }
      });
    } else {
      this.toastr.info(message.alreadyArchivedOrder)
    }
  }

  changePdfDisplayState(state: any) {
    this.toggleStateChange.emit(state)
  }

  navigateToPreview() {
    if (this.token) {
      window.open(`appraiser-link/order-preview/${this.orderId}/${this.token}?reviewId=${this.reviewId}`, "_blank");
    } else {
      window.open(`/orders/order-preview/${this.orderId}?reviewId=${this.reviewId}`, "_blank");
    }
  }

  submitOrderToEAD(): void {
    this.orderService.submitToEAD(this.orderId)
    .then(() => {
      this.toastr.success(message.submissionToEAD);
    })
    .catch((error) => {
      this.toastr.error(error?.error?.detail);
    })
  }

  submitOrderToUCDP(): void {
    this.orderService.submitToUCDP(this.orderId)
    .then(() => {
      this.toastr.success(message.submissionToUCDP);
    })
    .catch((error) => {
      this.toastr.error(error?.error?.detail);
    })
  }

  openOverrideSubmissionModal(title: string) {
    const dialogRef = this.dialog.open(OverrideSubmissionComponent, {
      panelClass: ['documents-modal'],
      maxWidth: '100%',
      autoFocus: false,
      disableClose: true,
      data: {
        orderId: this.orderId,
        title
      },
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.orderHeaderUpdated.emit({ reloadDoc: false, isActionPerformed: true });
      }
    });
  }

  deleteAppraisal(): void {
    if(this.eadAppraisalNumber && this.eadAppraisalNumber !== this.appraisalNumber.APPRAISAL_ONE && (!this.ucdpAppraisalNumber || this.ucdpAppraisalNumber === this.appraisalNumber.APPRAISAL_ONE)) {
      this.openDeleteAppraisalConfirmationModal('EAD');
    } else if(this.ucdpAppraisalNumber && this.ucdpAppraisalNumber !== this.appraisalNumber.APPRAISAL_ONE && (!this.eadAppraisalNumber || this.eadAppraisalNumber === this.appraisalNumber.APPRAISAL_ONE)) {
      this.openDeleteAppraisalConfirmationModal('UCDP');
    } else {
      this.openDeleteAppraisalModal('Delete Appraisal');
    }
  }

  openDeleteAppraisalModal(title: string) {
    const dialogRef = this.dialog.open(DeleteAppraisalComponent, {
      panelClass: ['documents-modal'],
      maxWidth: '100%',
      autoFocus: false,
      disableClose: true,
      data: {
        canDeleteEAD: this.eadAppraisalNumber && this.eadAppraisalNumber !== this.appraisalNumber.APPRAISAL_ONE,
        canDeleteUCDP: this.ucdpAppraisalNumber && this.ucdpAppraisalNumber !== this.appraisalNumber.APPRAISAL_ONE,
        orderId: this.orderId,
        title
      },
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.openDeleteAppraisalConfirmationModal(result);
      }
    });
  }

  openDeleteAppraisalConfirmationModal(type: string): void {
    const dialogRef = this.dialog.open(OrderCompletedModalComponent, {
      panelClass: ['order-complete-modal'],
      maxWidth: '100%',
      autoFocus: false,
      disableClose: true,
      data: {
        heading: 'Confirmation',
        contentData: type === 'EAD' ? message.deleteEADappraisalContent : message.deleteUCDPappraisalContent,
        orderId: this.orderId,
        actionHeading: 'Delete'
      },
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        type === 'EAD' ? this.deleteEADappraisal() : this.deleteUCDPappraisal();
      }
    });
  }

  deleteUCDPappraisal(): void {
    this.orderService.deleteUCDPappraisal(this.orderId)
    .then(() => {
      this.toastr.success(message.deleteUcdpAppraisalMessage);
      this.orderHeaderUpdated.emit({ reloadDoc: false, isActionPerformed: true });
    })
    .catch((error) => {
      this.toastr.error(error?.error?.detail);
    })
  }

  deleteEADappraisal(): void {
    this.orderService.deleteEADappraisal(this.orderId)
    .then(() => {
      this.toastr.success(message.deleteEadAppraisalMessage);
      this.orderHeaderUpdated.emit({ reloadDoc: false, isActionPerformed: true });
    })
    .catch((error) => {
      this.toastr.error(error?.error?.detail);
    })
  }

  openLayoutSelectionDialog(): void {
    const dialogRef = this.dialog.open(LayoutPreferenceComponent, {
      panelClass: ['documents-modal', 'layout-placeholder'],
      maxWidth: '100%',
      width: '940px',
      autoFocus: false,
      disableClose: true,
      data: {
        isRightSidePreference: this.isRightSidePreference,
        currentUserId: this.currentUserId
      },
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.updateLayoutPreference.emit(true);
      }
    });
  }

}
