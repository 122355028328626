<div mat-dialog-title class="reviwerList__card-header">
  <h3>{{title}}</h3>
  <mat-icon fontSet="material-icons-outlined" mat-dialog-close>highlight_off</mat-icon>
</div>
<div mat-dialog-content>
  <div class="reviwerList__card">
    <div class="reviwerList__card-body">
      <div class="reviwerList__input-wrap">
        <p *ngIf="data.isBulkAssignment">The reviewer selected from below would be assigned <strong>{{data.orderIds.length}} selected orders</strong> from the order list.</p>
        <mat-form-field appearance="outline">
          <mat-label>Select a Reviewer</mat-label>
          <input
            type="text"
            matInput
            [formControl]="reviewerCtrl"
            [matAutocomplete]="auto"
            placeholder="Reviewer"
          />
          <mat-icon matSuffix>keyboard_arrow_down</mat-icon>
          <mat-autocomplete #auto="matAutocomplete" (optionSelected)="onReviewerSelected($event)">
            <mat-option *ngFor="let reviewer of filteredReviewers" [value]="reviewer">
              {{ reviewer }}
            </mat-option>
          </mat-autocomplete>
          <mat-hint *ngIf="showReviewerError()">
            Entered reviewer is not found.
          </mat-hint>
        </mat-form-field>

      </div>
    </div>
  </div>
</div>
<div mat-dialog-actions class="reviwerList__actions">
  <button class="reviwerList__btn-outlined" mat-dialog-close>Cancel</button>
  <button class="reviwerList__btn-filled" [disabled]="!reviewerCtrl.value" (click)="saveReviewAssignment()">Save</button>
</div>
