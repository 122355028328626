import { Component, Inject } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ToastService } from 'src/app/core/services/toast.service';
import { message } from 'src/app/shared/constants/alerts_messages';
import { RuleEngineList, RuleEngineListSingular } from 'src/app/shared/enums/checkList';
import { RulesService } from '../rules.service';
import { SharedService } from 'src/app/shared/shared.service';

@Component({
  selector: 'app-create-restricted-words',
  templateUrl: './create-restricted-words.component.html',
  styleUrls: ['./create-restricted-words.component.scss']
})
export class CreateRestrictedWordsComponent {
  ruleEngine = RuleEngineList;
  addRestrictedWords: FormGroup;
  submitted = false;
  filteredCategories: any;
  categories: any = [];
  clientId: any;
  isEditMode = false;

  constructor(private toastr: ToastService,
    private rulesService: RulesService,
    private sharedService: SharedService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<any>) {
    this.addRestrictedWords = new FormGroup({
      word: new FormControl('', [Validators.required]),
      category_id: new FormControl('', [Validators.required])
    });

    this.rulesService.getCategories().then((categories: any) => {
      if (categories && categories.data.length > 0) {
        this.categories = categories.data;
        this.filteredCategories = this.categories;
        this.clientId = data.clientId;
        if (data?.data?.id) {
          this.addRestrictedWords.get('word')?.setValue(data.data.word)
          this.addRestrictedWords.get('category_id')?.setValue(data.data?.restricted_words_category?.id)
          this.isEditMode = true;
        }
      }
    })

  }

  getTitle(categoryId: string) {
    const result = this.categories.find((category:any) => category.id === categoryId);
    return result ? `${result.category}`: '';
  }

  onAddRestrictedWords(formValue: any): void {
    this.submitted = true;
    if (!this.addRestrictedWords.valid) {
      return;
    }
    const param: FormData = new FormData();
    param.append('rule', JSON.stringify(formValue));

    if (this.isEditMode) {
      this.updateRestrictedWord(param);
    } else {
      this.createRestrictedWord(param);
    }
  }

  createRestrictedWord(param: any) {
    this.rulesService.createRestrictedWord(param).then((response) => {
      this.toastr.success(`${RuleEngineListSingular.RESTRICTED_WORD} ${message.ruleCreated}`);
      this.dialogRef.close(true);
    }, error => {
      this.toastr.error(error.error.detail);
    })
  }

  updateRestrictedWord (param: any) {
    const slug = this.clientId ? `clients/restricted-words/${this.clientId}` : 'restricted_words/'
    this.sharedService.ruleEngineUpdateStatues(slug, param, this.data.data.id).subscribe(
      (response) => {
        this.toastr.success(`${RuleEngineListSingular.RESTRICTED_WORD} ${message.ruleUpdated}`);
        this.dialogRef.close(true);
      },
      (error) => {
        this.toastr.error(error.error.detail);
      },
    );
  }


  removeSpaces(control: any): void {
    const newValue = control.value.trim();
    control.setValue(newValue);
  }

  filterOptions(value: any) {
    if (value) {
      this.filteredCategories = this.categories.filter((category: any) =>
      category?.category?.toLowerCase().startsWith(value.toLowerCase()));
    } else {
      this.filteredCategories = this.categories;
    }
  }
}
