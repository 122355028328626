import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable()
export class EventEmitterService {
  pageTitleOnHeaderEvent: Subject<string> = new Subject();
  notificationCount = new BehaviorSubject<{count: number, is_new_release: boolean}>({count: 0, is_new_release: false});
  data = this.notificationCount.asObservable();
  tableActionsEvent: Subject<string> = new Subject();
  refreshTableEvent: Subject<string> = new Subject();

  emitPageTitleOnHeader(value: string): void {
    this.pageTitleOnHeaderEvent.next(value);
  }

  updateNotificationCount(count: number, is_new_release: boolean) {
    this.notificationCount.next({count, is_new_release});
  }

  emitTableActionsOnClick(value: string): void {
    this.tableActionsEvent.next(value);
  }

  emitRefreshTable(value: string): void {
    this.refreshTableEvent.next(value);
  }
}
