<div mat-dialog-title class="completion-modal__card-header">
  <h3>{{heading}}</h3>
  <mat-icon fontSet="material-icons-outlined" mat-dialog-close>highlight_off</mat-icon>
</div>
<div mat-dialog-content>
  <div class="completion-modal__card">
    <div class="completion-modal__card-body" *ngIf="!isHtml">
      <p>{{message}}</p>
    </div>
    <div class="completion-modal__card-body" *ngIf="isHtml">
      <p [innerHTML]="message"></p>
    </div>
  </div>
</div>
<div mat-dialog-actions class="completion-modal__actions">
  <button class="completion-modal__btn-outlined" mat-dialog-close>Close</button>
  <button *ngIf="heading !== 'Changes Saved' && heading !== 'NAN Standard Rule Details'" class="completion-modal__btn-filled" [ngClass]="{'danger': btn === 'danger'}" (click)="Done()">{{action ? action : btn === 'danger' ? 'Delete': 'Done'}}</button>
</div>
